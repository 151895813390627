import { Spin } from 'antd'
import { Group } from 'joinr-dashboard-client-api'
import _ from 'lodash'
import React, { useState } from 'react'
import { PageGeneric } from '../models/pagegeneric'
import { setClassName } from '../utils/setClassName'
import GroupCardSquare from '../components/GroupCardSquare'
import GroupCardHorizontal from '../components/GroupCardHorizontal'
import { LISTVIEW_SELECTED, LISTVIEW_UNSELECTED, OVERVIEW_SELECTED, OVERVIEW_UNSELECTED } from '../assets/img/groups'
import { copyToClipboard } from '../utils/clipboard'

interface GroupProps extends PageGeneric {
    groups: Group[]
    loading: boolean;
}

const Groups = (props: GroupProps) => {
    const pageName = "Groups"
    const n = setClassName(pageName)

    const [columnLayout, setColumnLayout] = useState<boolean>(true)



    return (
        <div className={pageName}>
            {
                props.loading ? <Spin className={n('spinner')} size="large" />
                    : <><div className={n('top')}>
                        <div className={n('top-title')}>
                            This is a comprehensive summary of all groups you own or are part of
                        </div>
                        <div className={n('top-icons')}>
                            <img className={n('top-icons_grid-layout')} src={columnLayout ? OVERVIEW_UNSELECTED : OVERVIEW_SELECTED} alt="" onClick={() => {
                                setColumnLayout(false)
                            }} />
                            <img className={n('top-icons_column-layout')} src={columnLayout ? LISTVIEW_SELECTED : LISTVIEW_UNSELECTED} alt="" onClick={() => {
                                setColumnLayout(true)
                            }} />
                        </div>
                    </div>
                        {columnLayout
                            ? <GroupCardHorizontal
                                groups={props.groups} copyText={copyToClipboard} />
                            : <GroupCardSquare
                                groups={props.groups} copyText={copyToClipboard} />
                        }
                    </>}
        </div>
    )
}

export default Groups