import { GroupDay } from 'joinr-dashboard-client-api';
import { Image as joinrImage, Position, Waypoint as WaypointInterface } from 'joinr-dashboard-client-api/lib/api/models/Waypoint';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DELETE, PLACEHOLDER_WAYPOINT } from '../assets';
import { CLICK_IMAGE_ICON, START_END_POINT, WAYPOINT } from '../assets/img/roadbook';
import { Geometry } from '../utils/maputils';
import { setClassName } from '../utils/setClassName';
import { useDebounce } from '../utils/useDebounce';
import Input from './generic/Input';
import InputAutocomplete from './LocationDropdown';
import Uploader from './generic/Uploader';
import { RoadbookContext } from '../context/roadbook';
import { useWaypointFormatter } from '../hooks/useWaypointFormatter';

interface WaypointProps {
    waypoint: WaypointInterface
    index: number
    indexTotal: number;
    onDeleteWaypointClicked: (waypoint: WaypointInterface) => void;
    editMode: boolean;
    onTextAreaResize: () => void
    groupDay: GroupDay
}

const Waypoint = (props: WaypointProps) => {
    const { formatToJoinrWaypoint } = useWaypointFormatter();
    const [previewImage, setPreviewImage] = useState<string>()

    // REFS
    const waypointEl = useRef<HTMLDivElement>(null)
    const textAreaEl = useRef<HTMLDivElement>(null)

    // GENERIC VARIABLES
    const componentName = "Waypoint"
    const n = setClassName(componentName)
    const waypointHeight = waypointEl.current?.clientHeight

    // CONTEXT
    const { handleUpdateDirtyDays: updateDirtyDays, editMode, sortWaypoints, setSelectedGoogleWaypoint, setBounds, setCoords, editWaypointProperty, updateImage } = useContext(RoadbookContext)


    // STATE
    const debounceEditWaypointProperty = useDebounce(editWaypointProperty, 300);
    const debounceTextAreaResize = useDebounce((props.onTextAreaResize), 0);
    const [descriptionText, setDescriptionText] = useState<string | undefined>(props.waypoint.description_text)

    // TITLE & DESCRIPTION CHANGES
    const handleTitle = useMemo(() => {
        if (props.index === 0) {
            return 'Route Start'
        }
        else if (props.index === (props.indexTotal - 1)) {
            return 'Route End'
        }
        else {
            return `Waypoint ${props.index}`
        }
    }, [props.index, props.indexTotal])

    // ICON CHANGES
    const handleIcon = useMemo(() => {
        if (props.index === 0) {
            return START_END_POINT
        }
        else if (props.index === (props.indexTotal - 1)) {
            return START_END_POINT
        }
        else {
            return WAYPOINT
        }
    }, [props.index, props.indexTotal])

    const isDescriptionHidden = () => {
        if (!descriptionText && !editMode) {
            return 'hidden'
        }
        else return ''
    }

    useEffect(() => {
        setDescriptionText(props.waypoint.description_text)
    }, [props.waypoint.description_text])

    const doesRouteExist = () => {
        if ((props.waypoint.position.latitude === 1) && (props.waypoint.position.longitude === 1)) {
            return false
        }
        else return true
    }

    const convertBlobToImage = (blob: Blob) => {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
            const dataURL = event.target?.result as string;
            setPreviewImage(dataURL);
        };

        reader.readAsDataURL(blob);
    }

    return (
        <div className={componentName} >
            <Uploader
                disabled={!props.editMode}
                crop={false}
                onImageLoaded={(file) => {
                    convertBlobToImage(file)
                    editWaypointProperty(file, 'imageFile', props.groupDay, props.waypoint)
                }}
                showUploadList={false}
                uploadButton={<>
                    <div className={n('image-container')} style={{ height: waypointHeight }}>
                        {
                            previewImage || props.waypoint.image ?
                                <img className={n('delete-image-icon')}
                                    src={DELETE}
                                    alt="delete image"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setPreviewImage(undefined)
                                        editWaypointProperty(undefined, "image", props.groupDay, props.waypoint)
                                        editWaypointProperty(undefined, 'imageFile', props.groupDay, props.waypoint)
                                    }}
                                />
                                : null
                        }

                        <div className={n('fade-style')}>
                        </div>
                        <img className={n('click-image-icon')} src={CLICK_IMAGE_ICON} alt="click image" />
                        <div className={n('fade-screen')}>
                        </div>
                        <img className={n('image')} src={previewImage || props.waypoint.image?.resource_thumbnail_url || PLACEHOLDER_WAYPOINT} alt="" />
                    </div>
                </>}
            />

            <div className={n('flex-col')} ref={waypointEl}>
                <div className={n('flex-row')}>
                    <div className={n('start-end-icon-container')}>
                        <img className={n('start-end-icon')} src={handleIcon} alt="start" />
                    </div>
                    <div className={n('flex-col')}>
                        <div className={n('flex-row')}>
                            <InputAutocomplete
                                title={handleTitle}
                                titleColor={'#FFF'}
                                titleBold
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onInputChange={() => { }}
                                placeholder={'Enter a destination'}
                                onWaypointSelected={(value: google.maps.places.PlaceResult) => {
                                    const formattedWaypoint = formatToJoinrWaypoint(value, props.waypoint)
                                    formattedWaypoint && editWaypointProperty(formattedWaypoint, 'destination', props.groupDay, props.waypoint)
                                    if (value.geometry) {
                                        setBounds(undefined) // can't panmap w/ center prop when fitbounds active
                                        setCoords(undefined)
                                        setSelectedGoogleWaypoint(value.geometry.location)
                                    }
                                }}
                                defaultValue={formattedAddress(props.waypoint)}
                            />
                            <img className={n(`delete-icon ${handleTitle.toString().replace(/\s/g, '')}`)} src={DELETE} alt="delete" onClick={() => {
                                props.waypoint._id &&
                                    props.onDeleteWaypointClicked(props.waypoint)
                            }} />
                        </div>


                        {
                            doesRouteExist() ?
                                <Input
                                    ref={textAreaEl}
                                    inputValue={descriptionText}
                                    textAreaPlaceholder={editMode ? 'Add Description' : ''}
                                    defaultValue={props.waypoint.description_text}
                                    onInputChange={(value) => {
                                        setDescriptionText(value)
                                        debounceEditWaypointProperty(value, 'description', props.groupDay, props.waypoint)
                                    }}
                                    className={`${n('text-area')} ${isDescriptionHidden()}`}
                                    textArea={true}
                                    textAreaEditable={props.editMode}
                                    onTextAreaResize={() => {
                                        debounceTextAreaResize()
                                    }}
                                    imgRight={DELETE}
                                />
                                : null
                        }
                    </div>

                </div>

            </div>
        </div>

    )
}

export const formattedAddress = (waypoint: WaypointInterface) => {
    if (waypoint.position.latitude !== 1 && waypoint.position.longitude !== 1) {
        return `${waypoint.street}${waypoint.city ? ', ' + waypoint.city : ''}${waypoint.state ? ', ' + waypoint.state : ''}${waypoint.country ? ', ' + waypoint.country : ''}`
    }
    else return ''
}


export default Waypoint