
import { importGroupDayRoute } from 'joinr-dashboard-client-api';
import { GroupDay } from 'joinr-dashboard-client-api';
import { API_CONFIG } from '../App';
import { GroupPage } from '../models/grouppage';
import { errorToString } from '../api/errors';
import { useCallback } from 'react';
import _ from 'lodash';
import { Waypoint } from 'joinr-dashboard-client-api/lib/api/models/Waypoint';



export const generateDay = (baseDayToMergeWith: GroupDay) => {
    const newDay = new GroupDay({})
    _.merge(newDay, baseDayToMergeWith)
    return newDay
}

export const transformToAPIFormat = (days: GroupDay[]) => {
    return days.map((day: GroupDay) => {
        const waypoints = day.waypoints.map(wp => {
            const waypoint = wp
            const newWP = {
                ...waypoint,
                position: {
                    id: waypoint.position._id,
                    latitude: waypoint.position.latitude,
                    longitude: waypoint.position.longitude,
                },
                ...(waypoint.image?.id || waypoint.image?._id) && {
                    image: {
                        _id: waypoint.image?.id || waypoint.image?._id
                    }
                },
                id: waypoint._id
            }
            delete newWP._id
            return newWP
        })
        return {
            route: day.route,
            getId: () => day.getId(),
            activate_at: day.activate_at,
            waypoints: waypoints
        }
    })
}


export const validateDays = (days: GroupDay[], deletedDayIDs: string[]) => {
    const validDirtyDays: GroupDay[] = days.map((day) => {
        const emptyWaypoint = day.waypoints[0]
        day.waypoints = day.waypoints.filter((waypoint) => {
            return (((waypoint.position.latitude !== 1) || (waypoint.position.longitude !== 1)) && !day.route?.routeUrl);
        });
        if (day.route?.getUrl && day.waypoints.length === 0) {
            day.waypoints = [emptyWaypoint]
        }
        else if ((day.waypoints.length === 0) && (!day.route?.getUrl)) {
            deletedDayIDs.push(day.getId()!);
        }
        return day;
    }).filter((day) => {
        return day.waypoints.length >= 1;
    });
    return validDirtyDays;
}

export const sendRoute = (day: any, resp: GroupDay, props: GroupPage) => {
    if (!day.route) return
    if (!day.route.getUrl) return

    const noRouteInputChange = resp.route && (day.route.getUrl === resp.route.getUrl)
    if (noRouteInputChange) return
    if (day.route && day.route.getUrl) {
        return importGroupDayRoute(API_CONFIG, props.session, props.groupId, resp.getId()!, day.route.getUrl)
            .catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
            })
    }
}

export const sortDays = (groupDays: GroupDay[]) => {
    // Sort by date
    return groupDays.sort(
        (objA, objB) => new Date(objA.activate_at).getTime() - new Date(objB.activate_at).getTime()
    );
}

export const sortWaypoints = (waypoints: Waypoint[]) => {
    const sortedAsc = waypoints.sort(
        (objA, objB) => objA.sort_order - objB.sort_order,
    );

    const sortedWaypoints = sortedAsc.map((waypoint, index) => {
        if (index === 0) return { ...waypoint, type: 'start', sort_order: index + 1 }
        else if (index === sortedAsc.length - 1) return { ...waypoint, type: 'end', sort_order: index + 1 }
        else return { ...waypoint, type: 'marker', sort_order: index + 1 }
    })
    return sortedWaypoints
}