import { Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import Dragger from 'antd/lib/upload/Dragger'
import { getUserGallery } from 'joinr-dashboard-client-api'
import { PreviewImage } from 'joinr-dashboard-client-api'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { getSession } from '../api/SessionCompat'
import { API_CONFIG } from '../App'
import { CALENDAR, USER_PLACEHOLDER } from '../assets'
import Space from '../components/generic/Space'
import { PageGeneric } from '../models/pagegeneric'
import { setClassName } from '../utils/setClassName'
import Setting from '../components/generic/Setting'
import { BELL, CAMERA, CREDITCARD, EDIT, GLOBE, MAIL, PHONE, SECURITY } from '../assets/img/account'
import { UserContext } from '../context/user'
import ModalEditProfile from '../components/modals/ModalEditProfile'
import { formatPhone } from '../utils/formatPhone'
import { errorToString } from '../api/errors'
import { fetchUserMe } from '../emitters/fetchUserMe'
import useUpdateLogger from '../hooks/useUpdateLogger'
import * as toastr from 'toastr'
import { useUserGalleryUpload } from '../hooks/useUserGalleryUpload'

interface AccountProps extends PageGeneric {

}

const pageName = "Account"
const n = setClassName(pageName)


const Account = (props: AccountProps) => {
    const { handleUserGalleryUpload, profileImageUploading, setProfileImageUploading } = useUserGalleryUpload(API_CONFIG);
    const [imgPreviewUrl, setImgPreviewUrl] = useState<string>()
    const [userGallery, setUserGallery] = useState<PreviewImage[]>()
    const [editModalVis, setEditModalVis] = useState<boolean>(false)

    const session = getSession()

    useEffect(() => {
        fetchUserGallery()
    }, [])

    useEffect(() => {
        const image = grabRandomUserGalleryImage()
        setImgPreviewUrl(image)
    }, [userGallery])

    const fetchUserGallery = () => {
        getUserGallery(API_CONFIG, session!).then(resp => {
            setUserGallery(resp)
        })
    }

    const grabRandomUserGalleryImage = () => {
        if ((!userGallery) || (userGallery.length < 1)) return undefined

        return (
            userGallery[_.random(0, userGallery.length - 1)].getImageUrl512x()
        )
    }

    const renderSplashImage = () => {
        return (
            < div
                className={n('splash-image')}
                style={{ backgroundImage: `url(${imgPreviewUrl})` }}
            >
                <div className={n('splash-image-overlay')}></div>

                <div className={n('icon-camera-bg-splash')}>
                    <img className={n('icon-camera')} src={CAMERA} alt="" />
                </div>
                <Dragger
                    disabled={true}
                    name={'file'}
                    multiple={false}
                    beforeUpload={(file: RcFile) => {
                        return handleUserGalleryUpload(file, false).then(() => {
                            // All success actions after both operations complete
                            fetchUserGallery()
                            toastr.success('Image successfully added to user gallery')
                        })
                            .catch(err => {
                                errorToString(err).forEach(err => toastr.error(err))
                            })
                            .finally(() => {
                                // setSaveLoading(false)
                            })
                    }}
                    maxCount={1}
                    accept='.png, .jpg'
                    showUploadList={false}
                    className={n(`${'uploadBox'}`)}>
                </Dragger>

            </div>
        )
    }

    const renderProfileImage = () => {
        return (
            <Upload
                disabled={profileImageUploading}
                listType="picture-card"
                accept='.png, .jpg'
                showUploadList={false}
                beforeUpload={(file) => {
                    setProfileImageUploading(true)
                    return handleUserGalleryUpload(file, true).then(() => {
                        // All success actions after both operations complete
                        fetchUserMe.emit('fetch')
                        toastr.success('Profile image successfully updated')
                    })
                        .catch(err => {
                            errorToString(err).forEach(err => toastr.error(err))
                        })
                        .finally(() => {
                            setProfileImageUploading(false)
                        })
                }}
                maxCount={1}
            >
                <div
                    className={n(`userImage ${profileImageUploading} `)}
                >
                    <div className={n('icon-camera-bg')}>
                        <img className={n('icon-camera')} src={CAMERA} alt="" />
                    </div>
                    <div className={n('avatar-container')}>
                        {profileImageUploading ? <Spin className={n('profile-spinner')} size="large" /> : null}
                        {profileImageUploading ? <div className={n('avatar-overlay')}></div> : null}
                        <img className={n(`avatar ${profileImageUploading}`)} src={props.user?.previewImage?.getImageUrl128x() || USER_PLACEHOLDER} alt="user avatar" />
                    </div>

                </div>
            </Upload>
        )
    }

    const renderHeaderImages = () => {
        return (
            <>
                {renderSplashImage()}
                {renderProfileImage()}
            </>
        )
    }

    const renderDivider = () => {
        return <>
            <Space height={"2.5rem"} />
            <div className={'divider'} />
            <Space height={"2.5rem"} />
        </>
    }

    return (
        <div className={pageName}>
            {
                !props.user
                    ? <Spin size="large" />
                    : <>
                        { }
                        {renderHeaderImages()}
                        <div className={n('top')}>
                            <div className={n('top-flex')}>
                                <div className={n('user-name')}>
                                    {props.user?.displayName}
                                </div>
                                <div className={n('user-joined')}>
                                    <img className={n('icon-calendar')} src={CALENDAR} alt="" />
                                    Joined
                                    <span className={n('semicolon')}>:</span>
                                    {/* TODO: Month doesn't match PAW */}
                                    {props.user?.getCreatedAt()?.getMonth().valueOf()}.
                                    {props.user?.getCreatedAt()?.getDate().toString()}.
                                    {props.user?.getCreatedAt()?.getFullYear().toString()}
                                </div>
                            </div>
                            <div className={n('user-location')}>
                                {`${props.user?.homeLocation?.city}, ${props.user?.homeLocation?.country}` || 'Frankfurt, DE (placeholder)'}
                            </div>
                        </div>

                        <Space height={'5.5rem'} />
                        <div className={n('column-content')}>
                            <div className={n('left')}>
                                <div className={n('status')}>
                                    {/* {status} TODO */}
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut ducimus animi, temporibus consequuntur minima aliquid! In adipisci, dolorem minus assumenda temporibus reiciendis eveniet, aliquam rerum ut magni dignissimos laudantium odit?
                                </div>
                                <Space height={'5rem'} />
                                <div className={n('socials-option')}>
                                    <img className={n('socials-icon')} src={PHONE} alt="" />
                                    <div className={n('socials-text')}>
                                        <span>{formatPhone(props.user.phoneNumber)}</span>
                                    </div>
                                </div>
                                <Space height={'3rem'} />
                                <div className={n('socials-option')}>
                                    <img className={n('socials-icon')} src={GLOBE} alt="" />
                                    <div className={n('socials-text')}>
                                        {/* TODO: What is this? */}
                                        https://www.petesso.de
                                    </div>
                                </div>
                                <Space height={'3rem'} />
                                <div className={n('socials-option')}>
                                    <img className={n('socials-icon')} src={MAIL} alt="" />
                                    <div className={n('socials-text')}>
                                        {props.user.emailAddress}
                                    </div>
                                </div>
                            </div>
                            <div className={n('right')}>
                                <div className={n('settings-text')}>
                                    ACCOUNT SETTINGS
                                </div>
                                <Space height={'3rem'} />
                                <Setting
                                    icon={EDIT}
                                    title="Edit Profile"
                                    description="Lorem ipsum dolor sit amet"
                                    onClick={() => setEditModalVis(true)}
                                />
                                {renderDivider()}
                                <Setting
                                    icon={SECURITY}
                                    title="Security"
                                    description="Lorem ipsum dolor sit amet"
                                />
                                {renderDivider()}
                                <Setting
                                    icon={CREDITCARD}
                                    title="Payment Method"
                                    description="Lorem ipsum dolor sit amet"
                                />
                                {renderDivider()}
                                <Setting
                                    icon={BELL}
                                    title="Notifications"
                                    description="Lorem ipsum dolor sit amet"
                                />
                            </div>
                        </div>
                        <ModalEditProfile
                            user={props.user}
                            okButton={{
                                text: "",
                                onClick: () => { return }
                            }}
                            visible={editModalVis}
                            changeVisible={setEditModalVis}
                            outsideClickClose={false}
                        />
                    </>
            }
        </div>
    );
}

export default Account