import { UserOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UserContext } from '../context/user';
import { setClassName } from '../utils/setClassName';
import { USER_PLACEHOLDER } from '../assets';
import { BELL, SEARCH } from '../assets/img/header';
import Search from './generic/Search';

interface HeaderProps {
}

const Header = (props: HeaderProps) => {
    const componentName = "Header"
    const n = setClassName(componentName)

    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()
    const { user } = useContext(UserContext)

    const location = useLocation()
    const locationText = location.pathname.split('/')[1]; // Get the second segment directly

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className={componentName}>
            <div className={n('active-tab-title')}>
                {locationText.toUpperCase()}
            </div>

            <div className={n('right')}>
                <Search
                    value={searchTerm}
                    onChange={handleSearch}
                    icon={SEARCH}
                    className={n('search-box')}
                />
                <img className={n('bell-icon')} src={BELL} alt="" />
                <div className={n('account')} onClick={() => { navigate('/account') }}>
                    {
                        user && user.previewImage && user.previewImage.getImageUrl64x &&
                        <img className={n('user-avatar')} src={user.previewImage && user?.previewImage?.getImageUrl64x() || USER_PLACEHOLDER} alt="" />

                    }
                </div>
            </div>

        </div>
    )
}

export default Header