import Geocode from "react-geocode";
import MemoryCache from 'lscache'

export const API_KEY = 'AIzaSyDh8jhbWQcyIaUvsFBLWcEHaOvKX1TBkqU'
Geocode.setApiKey(API_KEY)
export interface LocationDetails {
    city?: string;
    zip?: string;
    state?: string;
    street?: string;
    streetNumber?: string
    country?: string;
    formatted_address: string;
    location: {
        latitude: number;
        longitude: number;
    }
}

export interface DeliveryCalculation {
    locationDetails?: LocationDetails;
}

export type Types = 'street_number' | 'postal_code' | 'route' | 'sublocality_level_1' | 'sublocality' | 'political' | 'locality' | 'administrative_area_level_2' | 'administrative_area_level_1' | 'administrative_area_level_3' | 'country' | 'subpremise' | 'neighborhood'
export interface AddressComponent {
    long_name: string,
    short_name: string,
    types: [Types]
}
export interface Geometry {
    location: {
        lat: number | (() => number)
        lng: number | (() => number)
    }
}

export interface GoogleDetails {
    address_components?: AddressComponent[],
    formatted_address?: string,
    geometry?: Geometry,
    place_id?: string
}

export const LoadingMessages = {
    IN_RANGE: 'You’re in range for free delivery!',
    NOT_IN_RANGE: 'You’re not in range for free delivery!',
    LOADING: 'Loading...',
    ERROR_CALCULATING_DISTANCE: 'Couldn\'t calculate distance!',
    LOCATION_NOT_LOADED: 'Couldn\'t find your location!',
    ADDRESS_NOT_LOADED: 'Couldn\'t load adress for your location!',
    INVALID_DEFAULT_ADDRESS: 'Couldn\'t load location for your address! Please check your address in the settings or set a new address here',
    LOCATION_DISABLED: 'Enable location or enter location manually'
}

export function checkValidAddress(details: LocationDetails, requiredKeys?: (keyof LocationDetails)[]): string[] | true {
    const keys = requiredKeys || ['country', 'city', 'street', 'streetNumber']
    const missingKeys: string[] = []
    keys.forEach((key) => {
        if (!details[key]) {
            missingKeys.push(key)
        }
    })
    return missingKeys.length == 0 ? true : missingKeys
}

export function convertGoogle(details: GoogleDetails, streetNumberOptional?: number): LocationDetails | undefined {
    if (!details.geometry) { return undefined }

    const addressComp = details.address_components
    let city = addressComp && addressComp.find(comp => comp.types.includes('locality'))
    if (!city) {
        city = addressComp && addressComp.find(comp => comp.types.includes('sublocality_level_1'))
    }
    const postalCode = addressComp && addressComp.find(comp => comp.types.includes('postal_code'))
    const state = addressComp && addressComp.find(comp => comp.types.includes('administrative_area_level_1'))
    const street = addressComp && addressComp.find(comp => comp.types.includes('route'))
    let streetNumber = addressComp && addressComp.find(comp => comp.types.includes('street_number'))
    if (!streetNumber && streetNumberOptional != undefined) {
        streetNumber = { long_name: streetNumberOptional.toString(), short_name: streetNumberOptional.toString(), types: ['street_number'] }
    }
    const country = addressComp && addressComp.find(comp => comp.types.includes('country'))
    if (!details.formatted_address) {
        return undefined
    }
    return {
        country: country && country.long_name,
        location: {
            longitude: (typeof details.geometry.location.lng === 'function') ? details.geometry.location.lng() : details.geometry.location.lng,
            latitude: (typeof details.geometry.location.lat === 'function') ? details.geometry.location.lat() : details.geometry.location.lat,
        },
        formatted_address: details.formatted_address,
        city: city && city.long_name,
        zip: postalCode && postalCode.long_name,
        state: state && state.long_name,
        street: street && street.long_name,
        streetNumber: streetNumber && streetNumber.long_name
    }
}

export async function getFromZip(zip: string): Promise<LocationDetails> {
    const url = `https://maps.google.com/maps/api/geocode/json?address=${zip}&types=postal_code&key=${API_KEY}`
    const data = await fetch(url).then((r) => r.json())
    const location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    }
    return location;
}

export async function loadLocationForAddress(address: { address_line_1: string }): Promise<LocationDetails> {
    const data = await Geocode.fromAddress(address.address_line_1);
    const location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    }
    return location;
}

export function getLocationDetails(latitude: number, longitude: number): Promise<LocationDetails> {
    return Geocode.fromLatLng(latitude.toString(), longitude.toString()).then((data: any) => {
        const location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
        if (!location) {
            throw new Error('Couldn\'t determine location');
        }
        return location
    })
}

export async function loadLocationForLatLng(lat: number, lng: number): Promise<LocationDetails> {
    const data = await Geocode.fromLatLng(lat.toString(), lng.toString());
    const location = Array.isArray(data.results) && data.results.length > 0 && convertGoogle(data.results[0]);
    if (!location) {
        throw new Error('Couldn\'t determine location');
    }
    return location;
}

// export function addressToString(defaultAddress: UserAddress) {
//     let address = (defaultAddress.address_line) + '\n' +
//         defaultAddress.city + ' ' + defaultAddress.state + ' ' + (defaultAddress.zip !== null ? (defaultAddress.zip + '') : '')
//         + '\n' + defaultAddress.country
//     if (defaultAddress.first_name || defaultAddress.last_name) {
//         address = `${defaultAddress.first_name || ''} ${defaultAddress.last_name || ''}`.trim() + '\n' + address
//     }
//     return address
// }


function _loadLocation(): Promise<LocationDetails> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                resolve(getLocationDetails(position.coords.latitude, position.coords.longitude))
            }, (error) => {
                reject(error)
            },
            { enableHighAccuracy: false, timeout: 5000 }
        );
    })

}

export function getCurrentLocation(): Promise<LocationDetails> {
    return _loadLocation().catch(err => {
        throw new Error(LoadingMessages.LOCATION_DISABLED)
    })

}

export function validCords(lat?: number, lng?: number): boolean {
    return lat !== undefined && Math.abs(lat) <= 90 && lng !== undefined && Math.abs(lng) <= 180;
}

export function validCircle(lat?: number, lng?: number, radius?: number): boolean {
    return validCords(lat, lng) && radius !== undefined && radius > 0;
}


export function locateByIP(): Promise<{ location: { lat: number, lng: number } }> {
    const LOCAL_STORAGE_KEY = 'last_location'

    if (MemoryCache.get(LOCAL_STORAGE_KEY)) {
        try {
            return Promise.resolve(MemoryCache.get(LOCAL_STORAGE_KEY))
        } catch (error) {

        }
    }
    return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${API_KEY}`, {
        method: 'POST'
    }).then((r) => r.json()).then((data) => {
        MemoryCache.set(LOCAL_STORAGE_KEY, data, 10)
        return data
    })
}