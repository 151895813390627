import { Form, Tooltip } from 'antd';
import React, { useEffect, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { TOOLTIP } from '../assets';
import { API_KEY } from '../utils/maputils';
import { setClassName } from '../utils/setClassName';
import { InputProps } from './generic/Input';


interface LocationDropdownProps extends InputProps {
    onWaypointSelected: (places: any) => void;
}

const LocationDropdown = (props: LocationDropdownProps) => {
    const componentName = "LocationDropdown"
    const n = setClassName(componentName)

    const callbackRef = useRef(props.onWaypointSelected)
    const autocompleteRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        callbackRef.current = props.onWaypointSelected;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onWaypointSelected])

    const inputRef = useRef<HTMLInputElement>(null);

    const updateInputValue = (value: string) => {
        if (inputRef.current) {
            inputRef.current.value = value;
        }
    };

    useEffect(() => {
        props.inputValue &&
            updateInputValue(props.inputValue)
    }, [props.inputValue])

    return (
        <div style={{ width: props.width ? props.width : '100%' }} className={`${componentName} ${props.className}`}
        >
            {
                props.title &&
                <div className={n('title-wrapper')}>
                    <div style={{ color: props.titleColor || '#888888' }} className={n(`title ${props.titleBold ? 'bold' : ''}`)}>{props.title}</div>

                    {
                        props.info &&
                        <Tooltip
                            color={'rgba(64, 61, 62, 1)'} overlayStyle={{ whiteSpace: 'pre-line' }} title={props.info} trigger="hover">
                            <img className={n('tooltip')} src={TOOLTIP} alt="" />
                        </Tooltip>

                    }
                </div>

            }


            <Form.Item
                name={props.name}
                rules={props.rules}
            >
                <Autocomplete
                    className="location-input"
                    apiKey={API_KEY}
                    defaultValue={props.defaultValue}
                    placeholder={'Enter a city'}
                    options={{
                        types: ['address'],  // This restricts to cities
                        fields: ['address_components', 'formatted_address', 'geometry', 'name'],
                    }}
                    onPlaceSelected={(places: any) => {
                        callbackRef.current(places)
                    }}
                />

                {props.imgRight
                    ? < img className={n('img-right')} src={props.imgRight} alt="delete" />
                    : null
                }
                {
                    props.error
                        ? <div className={n('error')}>{props.error}</div>
                        : null
                }
            </Form.Item>
        </div>
    )
}

export default LocationDropdown

