import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { login } from 'joinr-dashboard-client-api';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { setSession } from '../api/SessionCompat';
import { API_CONFIG } from '../App';
import { LOGIN_ARTWORK, LOGIN_BACKGROUND, LOGO_LIGHT } from '../assets';
import Button from '../components/generic/Button';
import Input from '../components/generic/Input';
import Title from '../components/generic/Title';
import Space from '../components/generic/Space';
import { Spacing } from '../enums/spacing';
import { setClassName } from '../utils/setClassName';

const Login = () => {
    const componentName = "Login"
    const n = setClassName(componentName)

    const [error, setError] = useState<string>()
    const [password, setPassword] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [loading, setLoading] = useState<boolean>()

    const navigate = useNavigate()

    const onPasswordChange = (text: string) => {
        setPassword(text)
    }
    const onUsernameChange = (text: string) => {
        setEmail(text)
    }

    const handleLogin = () => {
        setLoading(true)
        login(API_CONFIG, email, password)
            .then(session => {
                setLoading(false)
                setSession(session)
                navigate('/')
            })
            .catch(err => {
                setLoading(false)
                console.error("login", err)
                setError(
                    (err && err.error && err.error.message) || "Invalid credentials",
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <img className={n('background-art')} src={LOGIN_BACKGROUND} alt="" />
            <div className={componentName}>
                <div className={n('header')}>
                    <img className={n('logo')} src={LOGO_LIGHT} alt="joinr logo" />
                </div>
                <main className={n('main-content')}>
                    <div className={n('left')}>
                        <img src={LOGIN_ARTWORK} alt="Artwork" />
                    </div>
                    <div className={n('right')}>
                        <div className={n("form-container")}>
                            <Title text="Please Log In!" size="large" />
                            <Space height={Spacing.input25} />
                            <Input
                                title={'Username'}
                                onInputChange={(text) => onUsernameChange(text)}
                                placeholder={'username'}
                                icon={<UserOutlined />}
                                inputValue={email}
                                onEnterPress={handleLogin}
                            />
                            <Space height={Spacing.input25} />
                            <Input
                                title={'Password'}
                                icon={<LockOutlined />}
                                placeholder="Password"
                                type="password"
                                name="password"
                                onInputChange={(text) => onPasswordChange(text)}
                                inputValue={password}
                                onEnterPress={handleLogin}
                            />
                            <Space height={Spacing.input10} />

                            <div className={n('forgot-password')}>
                                <div
                                    className={n('forgot-password-text')}
                                    onClick={() => console.log('TODO: reset pw')}>
                                    Forgot password
                                </div>
                            </div>
                            <Space height={Spacing.input25} />
                            <Button
                                text="Log in"
                                htmlType='submit'
                                className={n('login-btn')}
                                loading={loading}
                                onClick={handleLogin}
                            />
                            <Space height={Spacing.input10} />
                            <div className="error">{error}</div>
                            <Space height={Spacing.input10} />

                            <div className={n('promo-text')}>
                                Download the Joinr App now and become a customer of the Joinr Dashboard!
                            </div>

                        </div>
                    </div>
                </main>

            </div>
        </>
    );
};

export default Login