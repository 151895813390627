import { addGroupGalleryImage, createGroup, waitForGalleryUpload } from 'joinr-dashboard-client-api';
import React, { useEffect, useState } from 'react';
import * as toastr from 'toastr';
import { errorToString } from '../../api/errors';
import { getSession } from '../../api/SessionCompat';
import { API_CONFIG } from '../../App';
import { setClassName } from '../../utils/setClassName';
import Input from '../generic/Input';
import Modal from '../generic/Modal';
import Title from '../generic/Title';
import Uploader from '../generic/Uploader';
import { useNavigate } from 'react-router-dom';

interface ModalCreateGroupProps {
    visible: boolean;
    onVisibleChange: (visibility: boolean) => void;
    onCancelClick: () => void;
}

const ModalCreateGroup = (props: ModalCreateGroupProps) => {
    const navigate = useNavigate()

    const [name, setName] = useState<string>('')
    const [file, setFile] = useState<File | null>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const componentName = "ModalCreateGroup"
    const n = setClassName(componentName)

    useEffect(() => {
        if (!props.visible) {
            setError(null)
            setFile(null)
        }
    }, [props.visible])

    const createJoinrGroup = () => {
        const session = getSession()
        setLoading(true)

        return createGroup(API_CONFIG, session!, { group: { title: name } })
            .then((resp) => {
                console.log('file is: ', file)
                if (file) {
                    return addGroupGalleryImage(API_CONFIG, session!, resp.getId()!, true).then((item) => {
                        return fetch(item.upload_url, {
                            method: 'PUT',
                            body: file
                        }).then((img) => {
                            waitForGalleryUpload(API_CONFIG, session!, item._id).then(() => {
                                setLoading(false)
                                props.onVisibleChange(false)
                                navigate(`/group/${resp.getId()}/`)
                                toastr.success('Group successfully created!')
                            })
                        }).catch(err => {
                            errorToString(err).forEach(err => toastr.error(err))
                            setLoading(false)
                        })
                    }).catch(err => {
                        errorToString(err).forEach(err => toastr.error(err))
                        setLoading(false)
                    })
                }
                else {
                    setLoading(false)
                    props.onVisibleChange(false)
                    navigate(`/group/${resp.getId()}/`)
                    toastr.success('Group successfully created!')
                }
            }).catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
                setLoading(false)
            })
    }

    return (
        <div className={componentName}>
            <Modal
                title={'Create Group'}
                okButton={{
                    text: 'Create Group',
                    loading: loading,
                    onClick: () => {
                        if (name.trim() === '') {
                            setError('Please enter a name')
                        } else {
                            setError(null)
                            createJoinrGroup()
                        }
                    }
                }}
                changeVisible={props.onCancelClick}
                className={componentName}
                visible={props.visible}
            >
                <Input
                    className={n('name-input')}
                    title={'Name'}
                    placeholder={'Group Name'}
                    onInputChange={(inputValue) => setName(inputValue)} inputValue={name}
                    error={error}
                />
                <Title
                    text="Image" size="small" className="image-title"
                />
                <Uploader
                    listType="picture-card"
                    showUploadList={true}
                    crop={true}
                    onImageLoaded={file => setFile(file)}
                />
            </Modal>
        </div >
    )
}

export default ModalCreateGroup