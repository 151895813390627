// hooks/useGalleryUpload.ts
import { useState } from 'react';
import { getSession } from '../api/SessionCompat';
import { errorToString } from '../api/errors';
import * as toastr from 'toastr';
import { addGroupGalleryImage, addUserGalleryImage, waitForGalleryUpload } from 'joinr-dashboard-client-api';

// TODO: switch this to be defined in the dashboard-api
export interface ApiConfig {
    apiUrl: string;
    apiUrlPlain: string;
    apiVersion: string;
}

export const useUserGalleryUpload = (apiConfig: ApiConfig) => {
    const [profileImageUploading, setProfileImageUploading] = useState(false);

    const handleUserGalleryUpload = async (file: File, preview?: boolean) => {
        try {
            const item = await addUserGalleryImage(apiConfig, getSession()!, preview || false);

            await fetch(item.upload_url, {
                method: 'PUT',
                body: file
            });

            await waitForGalleryUpload(apiConfig, getSession()!, item._id);
            console.log('image upload finished');
        } catch (err: any) {
            errorToString(err).forEach(err => toastr.error(err));
            setProfileImageUploading(false);
        }
    };

    return {
        handleUserGalleryUpload,
        profileImageUploading,
        setProfileImageUploading
    };
};