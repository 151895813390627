import { Group } from 'joinr-dashboard-client-api'
import { setClassName } from '../utils/setClassName'
import _ from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PLACEHOLDER_GROUP_CARD } from '../assets'
import { COPY, MEMBER_COUNT } from '../assets/img/groups'

interface GroupCardSquareProps {
    groups: Group[]
    copyText: (secretKey: string | undefined) => Promise<void>
}

const GroupCard = ({ groups, copyText }: GroupCardSquareProps) => {
    const componentName = "GroupCardSquare"
    const n = setClassName(componentName)
    const navigate = useNavigate()

    return (
        <div className={n('group-cards')}>
            {
                groups.map(group => {
                    return (
                        <React.Fragment key={group.getId()}>
                            <div className={n('group-card')} onClick={() => {
                                navigate(`/group/${group.getId()}`)
                            }}>
                                {/* <div className={n('screen')}>
                                </div> */}
                                <div
                                    className={n('group-card-image')}
                                    style={{
                                        backgroundImage:
                                            group.previewImage
                                                ? `url(${group.previewImage.getImageUrl512x()})`
                                                : `url(${PLACEHOLDER_GROUP_CARD})`
                                    }}
                                >
                                    <div className={n('card-wrapper count')}>
                                        <div className={n('card-icon count')}>
                                            <img className={n('count-svg')} src={MEMBER_COUNT} alt="member-count" onClick={() => {
                                                navigate(`/group/${group.getId()}`)
                                            }} />
                                        </div>
                                        <div className={n('member-count-value')}>{`${group.members?.length || 0}`}</div>
                                    </div>
                                    <div className={n('card-description')}>
                                        {group.description}
                                    </div>

                                </div>
                                <div className={n('text-content')} onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    <div className={n('card-wrapper title')} onClick={() => {
                                        navigate(`/group/${group.getId()}`)
                                    }}>
                                        <div className={n('title-value')}>{group.title}</div>
                                    </div>

                                    <div className={n('card-wrapper key')} onClick={() => copyText(group.secretWord)}>
                                        <div className={n('secret-value')}>{group.secretWord}</div>
                                        <div className={n('card-icon copy')}>
                                            <img className={n('copy-svg')} src={COPY} alt="secret" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )

}

export default GroupCard