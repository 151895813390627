import { Switch } from 'antd'
import { updateGroup, Group, User } from 'joinr-dashboard-client-api'
import React, { useEffect, useState } from 'react'
import * as toastr from 'toastr'
import { errorToString } from '../../api/errors'
import { getSession } from '../../api/SessionCompat'
import { API_CONFIG } from '../../App'
import { Select, Input } from '../generic';
import { fetchSpecificGroup } from '../../emitters/fetchGroups'
import { GroupPage } from '../../models/grouppage'
import { isBlank } from '../../utils/notBlank'
import { setClassName } from '../../utils/setClassName'
import Modal, { ModalProps } from '../generic/Modal'
import Space from '../generic/Space'
import { Spacing } from '../../enums/spacing'
import DatePicker from '../generic/Datepicker'
import moment from 'moment'
import SelectLeader from '../SelectLeader'
import { fetchChangeGroupOwner } from '../../emitters/fetchChangeGroupOwner'


const pageName = 'ModalEditGroup'
const n = setClassName(pageName)

interface ModalEditGroup extends GroupPage, ModalProps { }

const ModalEditGroup = (props: ModalEditGroup) => {

    // Update group states
    const [name, setName] = useState<string>('')
    const [leader, setLeader] = useState<User>()
    const [secret, setSecret] = useState<string>('')
    const [description, setDescription] = useState<string | undefined>('')
    const [isPublic, setIsPublic] = useState<boolean>(false)
    const [startTime, setStartTime] = useState<Date | undefined>(undefined)
    const [eventType, setEventType] = useState<string>('')
    const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false)

    useEffect(() => {
        props.group &&
            setInitialValues(props.group)

    }, [props.group])

    const validateSecret = /^([a-z]{2,})-([a-z0-9]{3,})$/

    const validateInputs = () => {
        if (isBlank(name)) return 'Please enter a valid group name'
        else if (!validateSecret.test(secret)) return 'Please enter a valid secret name'
        else return
    }

    const setInitialValues = (group: Group) => {
        setName(group.title || '')
        setLeader(group.owner)
        setSecret(group.secretWord || '')
        setDescription(group.description)
        setIsPublic(group.isPublic)
        setEventType(group.eventType || '')
        setStartTime(group.startTime)
    }

    const updateGroupDetails = async () => {
        if (!validateInputs()) {
            setSaveBtnLoading(true)
            if (!props.group) return

            // changeGroupOwner(API_CONFIG, getSession()!,group.getId()!)
            updateGroup(API_CONFIG, getSession()!, props.group.getId()!, {
                group: {
                    title: name,
                    secret_word: secret,
                    group_description: description,
                    public: isPublic,
                    event_type: eventType,
                    start_time: startTime
                }
            })
                .then((resp) => {
                    leader &&
                        fetchChangeGroupOwner.emit('fetch')
                    toastr.success('Group successfully updated')
                    fetchSpecificGroup.emit('fetch')
                })
                .catch(err => {
                    errorToString(err).forEach(err => toastr.error(err))
                })
                .finally(() => {
                    setSaveBtnLoading(false)
                })
        }
    }

    const onEventChange = (value: any) => {
        setEventType(value)
    };

    const selectEventType = () => {
        return (
            <Select
                placeholder={"Rally"}
                title={'Type of Event'}
                options={[
                    {
                        label: 'Rally',
                        value: 'Rally',
                    },
                    {
                        label: 'Club',
                        value: 'Club',
                    },

                ]}
                value={eventType}
                onChange={onEventChange}
            />
        )
    }

    return (
        <div className={pageName}>

            {/* Edit group Modal */}
            <Modal
                okButton={{
                    text: "Save",
                    loading: saveBtnLoading,
                    tooltipError: validateInputs(),
                    onClick: () => {
                        updateGroupDetails()
                    },
                }}
                cancelButton={{
                    loading: saveBtnLoading,
                    onClick: () => {
                        if (!props.group) return
                        setInitialValues(props.group)
                    },
                    style: "cancel"
                }}
                visible={props.visible}
                changeVisible={props.changeVisible}
                outsideClickClose={false}
            >
                <Input
                    className={n('group-name')}
                    title={'Group Name'}
                    onInputChange={(value) => setName(value)}
                    inputValue={name.toUpperCase()}
                />

                <Space height={Spacing.input25} />
                <div className={n(' column-layout')}>
                    <SelectLeader
                        leader={leader}
                        setLeader={setLeader}
                        group={props.group!}
                        groupId={props.groupId}
                    />
                    <Input
                        title={'Secret Code'}
                        info={`Unique secret must be two lower case words separated by a dash.

                        First Word: 2+ letters
                        Second Word: 3+ letters and/or numbers 
                        Example: GR-Rally2
                        `}
                        onInputChange={(value) => setSecret(value)}
                        inputValue={secret}
                    />
                </div>
                <Space height={Spacing.input25} />
                <Input
                    textArea={true}
                    textAreaEditable={true}
                    title={'Description'}
                    onInputChange={(value) => setDescription(value)}
                    inputValue={description}
                />
                <Space height={Spacing.input25} />
                <div className={n(' column-layout')}>

                    {selectEventType()}
                    <DatePicker
                        caption={'Duration'}
                        selectedDate={moment(startTime)}
                        onDateChange={(startTime) => {
                            setStartTime(moment(startTime).toDate())
                        }} />

                </div>
                <Space height={'4rem'} />
                <div className={n(' flex')}>
                    {isPublic ? "Public" : "Private"}
                    <Space maxWidth='13rem' />
                    <Switch
                        checked={isPublic}
                        onChange={() => setIsPublic(!isPublic)}
                    />
                </div>
                <Space height={'3rem'} />
            </Modal>
        </div >
    )
}

export default ModalEditGroup