import React, { useContext, useState } from 'react';
import { Routes, useNavigate, useParams } from 'react-router';
import { API_CONFIG, RenderPageRedux as renderRoute } from './App';
import Header from './components/Header';
import { PageGeneric } from './models/pagegeneric';
import Admin from './pages/Admin';
import Gallery from './pages/Gallery';
import GroupDetail from './pages/GroupDetail';
import Roadbook from './pages/Roadbook';
import { RoadbookProvider } from './context/roadbook';
import { LoadingContextProvider } from './context/loading';
import GroupDetailsNavbar from './components/GroupDetailsNavbar';
import { UserContext } from './context/user';
import NotFound from './pages/NotFound';
import Members from './pages/Members';
import useGroupData from './hooks/useGroupData';
import useGalleryData from './hooks/useGalleryData';


interface GroupRoutingProps extends PageGeneric {
    // Simply re-using jsx styles from App
    renderRoute: ({ path, header, page }: renderRoute) => React.JSX.Element
}

const GroupRouting = ({ renderRoute, ...props }: GroupRoutingProps) => {
    const navigate = useNavigate()
    const [activeNavigationTab, setActiveNavigationTab] = useState<string | undefined>('')
    const { id: groupId } = useParams();
    const [loading, setLoading] = useState<boolean>(false)
    const { user } = useContext(UserContext)

    // 
    const { group, error } = useGroupData(API_CONFIG, props.session, groupId!);
    const { gallery, galleryLoading } = useGalleryData({
        apiConfig: props.apiConfig,
        session: props.session,
        groupId: groupId!
    });

    const tabs = React.useMemo(() => {
        const baseTabs = [
            {
                tab_name: 'Group Details',
                onClick: () => { navigate(`/group/${groupId}`) }
            },
            {
                tab_name: 'Roadbook',
                onClick: () => { navigate(`roadbook`) }
            },
            {
                tab_name: 'Members',
                onClick: () => { navigate(`members`) }
            },
            {
                tab_name: 'Gallery',
                onClick: () => { navigate(`gallery`) }
            }
        ];

        // Only add admin tab if we have all the data and user is admin
        if (group?.owner?.getId() && user?.getId() && group.owner.getId() === user.getId()) {
            return [...baseTabs, {
                tab_name: 'Admin',
                onClick: () => { navigate(`admin`) }
            }];
        }

        return baseTabs;
    }, [group?.owner?.getId(), user?.getId(), navigate, groupId]);


    const renderHeader = () => {
        if (error) return <></>
        else return (
            <>
                <Header

                />
                <GroupDetailsNavbar
                    group={group!}
                    key={'group-detail'}
                    activeTab={activeNavigationTab}
                    onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                    tabs={tabs}
                />
            </>

        )
    }

    if (error) return <NotFound />
    else return (
        <Routes>
            {renderRoute({
                path: '/',
                header: renderHeader(),
                page: <GroupDetail
                    key={groupId}
                    onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                    group={group!}
                    groupId={groupId || ''}
                    session={props.session}
                    apiConfig={API_CONFIG}
                />
            })}

            {renderRoute({
                path: 'roadbook',
                header: renderHeader(),
                page: <LoadingContextProvider value={{ loading, setLoading }}>
                    <RoadbookProvider
                        onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                        group={group!}
                        groupId={groupId || ''}
                        apiConfig={API_CONFIG}
                        session={props.session}>
                        <Roadbook
                            onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                            group={group!}
                            groupId={groupId || ''}
                            apiConfig={API_CONFIG}
                            session={props.session}
                        />
                    </RoadbookProvider>
                </LoadingContextProvider>
            })}

            {renderRoute({
                path: 'roadbook',
                header: renderHeader(),
                page: <LoadingContextProvider value={{ loading, setLoading }}>
                    <RoadbookProvider
                        onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                        group={group!}
                        groupId={groupId || ''}
                        apiConfig={API_CONFIG}
                        session={props.session}>
                        <Roadbook
                            onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                            group={group!}
                            groupId={groupId || ''}
                            apiConfig={API_CONFIG}
                            session={props.session}
                        />
                    </RoadbookProvider>
                </LoadingContextProvider>
            })}

            {renderRoute({
                path: 'gallery',
                header: renderHeader(),
                page: <Gallery
                    loading={galleryLoading}
                    gallery={gallery}
                    onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                    group={group!}
                    groupId={groupId || ''}
                    apiConfig={API_CONFIG}
                    session={props.session}
                />
            })}

            {renderRoute({
                path: 'admin',
                header: renderHeader(),
                page: <Admin
                    onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                    group={group!}
                    groupId={groupId || ''}
                    apiConfig={API_CONFIG}
                    session={props.session}
                />
            })}

            {renderRoute({
                path: 'members',
                header: renderHeader(),
                page: <Members
                    onActiveTabChange={(tab) => setActiveNavigationTab(tab)}
                    group={group!}
                    groupId={groupId || ''}
                    apiConfig={API_CONFIG}
                    session={props.session}
                />
            })}
        </Routes>
    )
}

export default GroupRouting