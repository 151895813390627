const START_END_POINT = require('./startend.svg')
const WAYPOINT = require('./waypoint.svg')
const GOOGLE_MAPS_ICON = require('./google_maps.svg')
const GMAP_ICON = require('./gmapslogo.svg')
const DOT = require('./dot.svg')
const CLICK_IMAGE_ICON = require('./clickimage.svg')

export {
    START_END_POINT,
    GOOGLE_MAPS_ICON,
    WAYPOINT,
    DOT,
    GMAP_ICON,
    CLICK_IMAGE_ICON,
}