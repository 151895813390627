import { addWaypointImage, createGroupDay, deleteGroupDay, getAllGroupDays, importGroupDayRoute, updateGroupDay } from 'joinr-dashboard-client-api';
import { Group } from 'joinr-dashboard-client-api';
import { GroupDay } from 'joinr-dashboard-client-api';
import { Image, Waypoint } from 'joinr-dashboard-client-api/lib/api/models/Waypoint';
import _ from 'lodash';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as toastr from 'toastr';
import { v4 as uuidv4 } from 'uuid';
import { errorToString } from '../api/errors';
import { API_CONFIG } from '../App';
import { isRouteValid } from '../components/RoadbookDay';
import { fetchSpecificGroup } from '../emitters/fetchGroups';
import { GroupPage } from '../models/grouppage';
import { UserContext } from '../context/user';
import { transformToAPIFormat, validateDays, sendRoute, sortWaypoints, sortDays, generateDay } from '../utils/roadbookutils';
import { getSession } from '../api/SessionCompat';
import { Image as joinrImage } from 'joinr-dashboard-client-api/lib/api/models/Waypoint';
import { LoadingContext } from './loading';

export interface RoadbookContextProps {
    dirtyDays: GroupDay[];
    editMode: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
    handleUpdateDirtyDays: (newDay: GroupDay) => void;
    deleteDay: (dirtyDay: GroupDay) => void;
    sortWaypoints: (waypoints: Waypoint[]) => Waypoint[]
    mergeNewWaypoint: (newDay: GroupDay) => void;
    setDirtyDays: React.Dispatch<React.SetStateAction<GroupDay[]>>
    createDirtyDay: (date: Date) => GroupDay
    addNewDay: () => void;
    refs: React.MutableRefObject<{
        [key: string]: HTMLDivElement | null;
    }>
    getBounds: () => void;
    extractWaypoints: () => Waypoint[];
    saveChanges: () => void;
    getDays: () => void;
    saveChangesModal: boolean;
    setSaveChangesModal: React.Dispatch<React.SetStateAction<boolean>>;
    isGroupOwner: () => boolean;
    cleanDays: GroupDay[] | undefined;
    bounds: google.maps.LatLngBounds | undefined;
    coords: google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[] | google.maps.LatLngLiteral[] | undefined
    setLastDayDate: React.Dispatch<React.SetStateAction<Date | undefined>>
    getAllGroupDaysLoading: boolean;
    confirmChangesLoading: boolean;
    group: Group | undefined;
    saveButtonDisabled: boolean;
    setActiveGroupDay: React.Dispatch<React.SetStateAction<GroupDay | undefined>>
    activeGroupDay: GroupDay | undefined;
    setSelectedGoogleWaypoint: React.Dispatch<React.SetStateAction<google.maps.LatLng | undefined>>
    selectedGoogleWaypoint: google.maps.LatLng | undefined
    setBounds: React.Dispatch<React.SetStateAction<google.maps.LatLngBounds | undefined>>
    setCoords: React.Dispatch<React.SetStateAction<google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[] | google.maps.LatLngLiteral[] | undefined>>
    editWaypointProperty: (value: string | joinrImage | Waypoint | File | undefined, task: 'description' | 'image' | 'imageFile' | 'destination', groupDay: GroupDay, waypoint: Waypoint) => void
    updateImage: (file: File, groupDay: GroupDay, waypoint: Waypoint) => void
    uploadImages: () => void;
}
export interface RoadbookProviderProps extends GroupPage {
    children: ReactNode
}

export interface JoinrWaypoint extends Waypoint {
    imageFile?: File;
}

export const RoadbookContext = createContext<RoadbookContextProps>({} as RoadbookContextProps);

export const RoadbookProvider = (props: RoadbookProviderProps) => {
    const refs = useRef<{ [key: string]: HTMLDivElement | null }>({})
    const { user } = useContext(UserContext)
    const { loading, setLoading } = useContext(LoadingContext);
    const [coords, setCoords] = useState<google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLng[] | google.maps.LatLngLiteral[] | undefined>()
    const [group, setGroup] = useState<Group>()
    const [cleanDays, setCleanDays] = useState<GroupDay[]>()
    const [dirtyDays, setDirtyDays] = useState<GroupDay[]>([])
    const [bounds, setBounds] = useState<google.maps.LatLngBounds>()
    const [deletedDayIDs, setDeletedDayIDs] = useState<string[]>([])
    const [newDayIDs, setNewDayIDs] = useState<string[]>([])
    const [getAllGroupDaysLoading, setGetAllGroupDaysLoading] = useState<boolean>(false)
    const [saveChangesModal, setSaveChangesModal] = useState<boolean>(false)
    const [confirmChangesLoading, setConfirmChangesLoading] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<boolean>(true)
    const [lastDayDate, setLastDayDate] = useState<Date>()
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false)
    const [activeGroupDay, setActiveGroupDay] = useState<GroupDay | undefined>()
    const [selectedGoogleWaypoint, setSelectedGoogleWaypoint] = useState<google.maps.LatLng | undefined>()

    useEffect(() => {
        if (!activeGroupDay || !activeGroupDay.route) return
        if (activeGroupDay.route?.getCoordinates.length >= 2) {
            const coords = activeGroupDay?.route?.getCoordinates.map(([lng, lat]) => ({ lat, lng }));
            setCoords(coords)
        }
        else {
            const myLatLng = new google.maps.LatLng({ lat: activeGroupDay.waypoints[0].position.latitude, lng: activeGroupDay.waypoints[0].position.longitude });
            setCoords(undefined)
            setBounds(undefined)
            setSelectedGoogleWaypoint(myLatLng)
        }
    }, [activeGroupDay])

    useEffect(() => {
        getBounds()
    }, [coords])

    useEffect(() => {
        fetchSpecificGroup.emit('fetch')
        setGroup(props.group)
        getDays()
        props.onActiveTabChange &&
            props.onActiveTabChange('Roadbook')
    }, [])

    useEffect(() => {
        RouteValidation()
        if (dirtyDays.length < 1) setEditMode(true)
        if (!dirtyDays.length) return
        const lastDate = new Date(dirtyDays[dirtyDays.length - 1].activate_at)
        setLastDayDate(lastDate)
    }, [dirtyDays])

    const updateDirtyDays = useCallback((newDay: GroupDay) => {
        const dayInStorage = dirtyDays.filter(existingDay => {
            return existingDay.getId() === newDay.getId()
        })
        const otherDaysInStorage = dirtyDays.filter(existingDay => {
            return existingDay.getId() !== newDay.getId()
        })
        const generatedDay = new GroupDay({})
        const existingData = _.merge(generatedDay, dayInStorage[0])
        const newDayMerged = _.merge(existingData, newDay)
        const sorted = sortDays([...otherDaysInStorage, newDayMerged])
        setDirtyDays([...sorted])
    }, [dirtyDays])

    // Create new day, edit wp property, merge it into existing waypoints, send day off to get merged 
    const editWaypointProperty = useCallback((value: string | joinrImage | JoinrWaypoint | File | undefined, task: 'description' | 'image' | 'imageFile' | 'destination', groupDay: GroupDay, waypoint: Waypoint) => {
        const newDay = generateDay(groupDay)
        const existingWaypoints = newDay.waypoints.filter((wp) => wp._id !== waypoint._id)
        let editedWaypoint = waypoint as JoinrWaypoint

        switch (task) {
            case 'destination':
                editedWaypoint = value as JoinrWaypoint
                break;

            case 'description':
                editedWaypoint.description_text = value as string
                break;

            case 'image':
                if (!value) delete editedWaypoint.image
                else editedWaypoint.image = value as joinrImage
                break;

            case 'imageFile':
                if (!value) delete editedWaypoint.imageFile
                editedWaypoint.imageFile = value as File
                break

            default:
                break;
        }

        const combined = [...existingWaypoints, editedWaypoint]
        newDay.waypoints = sortWaypoints(combined)
        updateDirtyDays(newDay)
    }, [updateDirtyDays])

    // IMAGE CHANGES
    const updateImage = useCallback((file: File, groupDay: GroupDay, waypoint: Waypoint): Promise<void> => {
        const formData = new FormData();
        formData.append("image", file)
        setLoading(true)

        return addWaypointImage(API_CONFIG, getSession()!, formData).then(resp => {
            editWaypointProperty(resp, 'image', groupDay, waypoint)
        }).catch((err => {
            errorToString(err).forEach(err => toastr.error(err))
        }))
    }, [editWaypointProperty, setLoading])


    const uploadImages = useCallback(() => {
        setConfirmChangesLoading(true)

        const dirtyDaysWithImages = dirtyDays.map(dirtyDay => ({
            ...dirtyDay,
            waypoints: dirtyDay.waypoints.filter((waypoint: JoinrWaypoint) => waypoint.imageFile)
        })).filter(dirtyDay => dirtyDay.waypoints.length > 0);

        const updateImagePromises = dirtyDaysWithImages.flatMap(day => {
            return day.waypoints.map((waypoint: JoinrWaypoint) =>
                updateImage(waypoint.imageFile!, day as any, waypoint)
            )
        });

        Promise.all(updateImagePromises).then(resp => {
            console.log('resp:', resp)
        }).catch(err => {
            errorToString(err).forEach(err => toastr.error(err))
        }).finally(() => {
            saveChanges()
        })
    }, [updateImage, dirtyDays])


    const RouteValidation = useCallback(() => {
        const validatedRoutes = dirtyDays.map((day) => {
            if (!day.route || !day.route.getUrl) return
            return isRouteValid(day.route.getUrl)
        })
        const hasFalse = validatedRoutes.some((value) => value === false);
        if (hasFalse) {
            setSaveButtonDisabled(true)

        }
        else setSaveButtonDisabled(false)
    }, [dirtyDays])

    const isGroupOwner = useCallback(() => {
        if (!props.group?.owner?.getId() || !user) return false
        if (props.group?.owner?.getId() !== user.getId()) return false
        return true
    }, [props.group?.owner, user])

    const deleteDay = useCallback((dirtyDay: GroupDay) => {
        const remainingGroupDays = dirtyDays.filter(value => {
            return value.getId() !== dirtyDay.getId()
        })
        setDirtyDays(remainingGroupDays.slice())

        if (newDayIDs.includes(dirtyDay.getId()!)) {
            const remaining = newDayIDs.filter(value => {
                value !== dirtyDay.getId()
            })
            setNewDayIDs(remaining)
        }

        if (!deletedDayIDs.includes(dirtyDay.getId()!)) {
            setDeletedDayIDs([...deletedDayIDs, dirtyDay.getId()!])
        }
    }, [dirtyDays])

    const mergeNewWaypoint = useCallback((newDay: GroupDay) => {
        const otherDaysInStorage = dirtyDays.filter(existingDay => {
            return existingDay.getId() !== newDay.getId()
        })

        const sorted = sortDays([...otherDaysInStorage, newDay])
        setDirtyDays([...sorted])
    }, [dirtyDays])

    const createDirtyDay = useCallback((date: Date) => {
        const non_API_Identifier = '3oi4219823'
        const waypointID = uuidv4()
        const dayID = non_API_Identifier + uuidv4()

        const newGroupDay = new GroupDay({ _id: dayID })
        setNewDayIDs([...newDayIDs, dayID])

        newGroupDay.waypoints = [{
            _id: waypointID,
            position: {
                _id: waypointID,
                latitude: 1,
                longitude: 1
            },
            sort_order: 1,
            type: 'start'
        }]
        newGroupDay.activate_at = new Date(date.setDate(date.getDate() + 1)).toString()
        return newGroupDay
    }, [newDayIDs])

    const addNewDay = useCallback(() => {
        setDirtyDays([...dirtyDays, createDirtyDay(lastDayDate || new Date())])
        toastr.success(`Day ${dirtyDays.length + 1} successfully added`, undefined, {
            positionClass: "toast-bottom-center",
            newestOnTop: true,
            toastClass: 'roadbook-toast'
        })
        setTimeout(() => {
            refs.current[dirtyDays.length]?.scrollIntoView()
        }, 50);
    }, [refs, dirtyDays, lastDayDate, createDirtyDay])

    const getBounds = useCallback(() => {
        if (!coords) return
        const bounds = new window.google.maps.LatLngBounds();
        coords?.forEach((marker) => {
            bounds.extend(marker);
        });
        setBounds(bounds)
    }, [coords])

    const getDays = useCallback(() => {
        setGetAllGroupDaysLoading(true)
        getAllGroupDays(API_CONFIG, props.session, props.groupId).then(resp => {
            setCleanDays(sortDays(resp))
            setDirtyDays(sortDays(resp))
        }).catch(err => {
            errorToString(err).forEach(err => toastr.error(err))
        }).finally(() => {
            setGetAllGroupDaysLoading(false)
        })
    }, [props.groupId, props.session])

    const saveChanges = useCallback(() => {
        if (!cleanDays) return
        let daysToUpdate
        const changedDays = dirtyDays.filter(dirtyDay => !cleanDays.includes(dirtyDay))
        const newDays = changedDays.filter(day => newDayIDs.includes(day.getId()!))

        daysToUpdate = changedDays.filter(day => !newDayIDs.includes(day.getId()!))
        daysToUpdate = validateDays(daysToUpdate, deletedDayIDs)
        daysToUpdate = transformToAPIFormat(daysToUpdate)

        const daysToDelete = deletedDayIDs.filter(id => !id.includes('3oi4219823'))

        let daysToCreate
        daysToCreate = validateDays(newDays, deletedDayIDs)
        daysToCreate = transformToAPIFormat(daysToCreate).filter(obj => newDayIDs.includes(obj.getId()!));

        const deletePromises = daysToDelete.map(deletedGroupDayID => {
            return deleteGroupDay(API_CONFIG, props.session, props.groupId, deletedGroupDayID)
        })

        const createPromises = daysToCreate.map(day => {
            return createGroupDay(API_CONFIG, props.session, props.groupId, {
                ...day,
                activate_at: day.activate_at,
                waypoints: day.waypoints as any
            }).then(resp => {
                return sendRoute(day, resp, props)
            }).catch(err => {
                console.error(err)
                errorToString(err).forEach(err => toastr.error(err))
            })
        })

        const updatePromises = daysToUpdate.map(day => {
            return updateGroupDay(API_CONFIG, props.session, props.groupId, day.getId()!, {
                ...day,
                waypoints: day.waypoints as any
            }).then(resp => {
                return sendRoute(day, resp, props)
            }).catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
            })
        })

        const combinedPromises = [...deletePromises, ...createPromises, ...updatePromises]

        Promise.all(combinedPromises).then(resp => {
            toastr.success('Your changes have been saved!', undefined, {
                positionClass: "toast-bottom-center",
                newestOnTop: true,
                toastClass: 'roadbook-toast'
            })
            setDeletedDayIDs([])
        }).catch(err => {
            errorToString(err).forEach(err => toastr.error(err))
        }).finally(() => {
            setTimeout(() => {
                setEditMode(false)
                setConfirmChangesLoading(false)
                setSaveChangesModal(false)
                getDays()
                setSelectedGoogleWaypoint(undefined)
                const copy = _.cloneDeep(activeGroupDay);
                setActiveGroupDay(copy)
            }, 1000);
        })
    }, [deletedDayIDs, props.groupId, props.session, cleanDays, dirtyDays, newDayIDs])

    const extractWaypoints = useCallback(() => {
        return dirtyDays.reduce((combinedArray: Waypoint[], groupDay) => {
            return [...combinedArray, ...groupDay.waypoints];
        }, []);
    }, [dirtyDays]);

    const contextItems = useMemo(() => ({
        refs,
        setDirtyDays,
        dirtyDays,
        editMode,
        setEditMode,
        handleUpdateDirtyDays: updateDirtyDays,
        deleteDay,
        sortWaypoints,
        mergeNewWaypoint,
        addNewDay,
        createDirtyDay,
        getBounds,
        getDays,
        extractWaypoints,
        saveChanges,
        saveChangesModal,
        setSaveChangesModal,
        isGroupOwner,
        cleanDays,
        bounds,
        coords,
        setLastDayDate,
        getAllGroupDaysLoading,
        confirmChangesLoading,
        group,
        saveButtonDisabled,
        setActiveGroupDay,
        activeGroupDay,
        setSelectedGoogleWaypoint,
        selectedGoogleWaypoint,
        setBounds,
        setCoords,
        editWaypointProperty,
        updateImage,
        uploadImages
    }), [refs, setDirtyDays, dirtyDays, editMode, setEditMode, updateDirtyDays, deleteDay, mergeNewWaypoint, addNewDay, createDirtyDay, getBounds, getDays, extractWaypoints, saveChanges, saveChangesModal, setSaveChangesModal, isGroupOwner, cleanDays, bounds, coords, setLastDayDate, getAllGroupDaysLoading, confirmChangesLoading, group, saveButtonDisabled, setActiveGroupDay, activeGroupDay, setSelectedGoogleWaypoint, selectedGoogleWaypoint, setBounds, setCoords, editWaypointProperty, updateImage, uploadImages])

    return (
        <RoadbookContext.Provider value={contextItems}>
            {props.children}
        </RoadbookContext.Provider>
    )
}

export const useDay = () => {
    const context: RoadbookContextProps = useContext(RoadbookContext);
    return context;
};

export const useSave = () => {
    const context: RoadbookContextProps = useContext(RoadbookContext);
    return context;
};