import { PlusOutlined } from '@ant-design/icons';
import { Modal as PreviewImageModal, Upload, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import React, { useState } from 'react';
import { getBase64 } from '../../utils/base64';
import { setClassName } from '../../utils/setClassName';

interface UploaderProps extends UploadProps {
    uploadButton?: React.ReactElement
    crop?: boolean;
    onImageLoaded?: (file: File) => void;
}

const Uploader = (props: UploaderProps) => {
    const componentName = "Uploader"
    const n = setClassName(componentName)

    // fileList only needed to return  image via handleImageCallback()
    const [fileList, setFileList] = useState<UploadFile[]>()

    const [file, setFile] = useState<File | null>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [previewTitle, setPreviewTitle] = useState<string>()

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!) as (string | undefined);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleShowUploadButton = () => {
        if (props.showUploadList) {
            if (file) {
                return null
            }
            else return props.uploadButton || uploadButton
        }
        else {
            return props.uploadButton || uploadButton
        }
    }

    const upload = <Upload
        accept='.png, .jpg'
        onPreview={handlePreview}
        onChange={({ fileList, event }) => {
            if (!event || event?.percent < 100) {
                return
            }
            setFileList(fileList)
            if (fileList.length > 0) {
                props.onImageLoaded && props.onImageLoaded(fileList[fileList.length - 1].originFileObj!)
            }
            else if ((fileList.length === 0)) {
                setFile(null)
                setPreviewVisible(false);
            }
        }}
        fileList={fileList}
        maxCount={1}
        {...props}
    >
        {handleShowUploadButton()}
    </Upload>

    const renderUpload = () => {
        if (props.crop) {
            return (
                <ImgCrop aspect={540 / 300}>
                    {upload}
                </ImgCrop>
            )
        }
        else return upload
    }

    return (
        <div className={componentName}>
            {renderUpload()}
            <PreviewImageModal
                visible={previewVisible}
                title={previewTitle}
                onCancel={() => setPreviewVisible(false)}
                onOk={() => setPreviewVisible(false)}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </PreviewImageModal>
        </div>
    )
}

export default Uploader