import React, { useState, useRef } from 'react';
import { setClassName } from '../../utils/setClassName';

interface SearchProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    icon: string;
    className?: string;
}

const Search: React.FC<SearchProps> = ({
    value,
    onChange,
    icon,
    className = ''
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const n = setClassName('Search');

    const handleIconClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isExpanded) {
            setIsExpanded(false);
            if (inputRef.current) {
                inputRef.current.blur();
            }
        } else {
            setIsExpanded(true);
            // Focus immediately after expanding
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0); // Using setTimeout to ensure the expansion happens first
        }
    };

    const handleBlur = (e: React.FocusEvent) => {
        // Only collapse on blur if we're not clicking the icon
        // We can check this by looking at the related target
        const isClickingIcon = e.relatedTarget?.classList.contains(n('icon'));
        if (!value && !isClickingIcon) {
            setIsExpanded(false);
        }
    };

    return (
        <div className={`${n('container')} ${className} ${isExpanded ? n('expanded') : ''}`}>
            <div className={n('input-wrapper')}>
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search..."
                    value={value}
                    onChange={onChange}
                    onBlur={handleBlur}
                    className={n('input')}
                />
            </div>
            <img
                src={icon}
                alt="search"
                className={n('icon')}
                onClick={handleIconClick}
                tabIndex={0} // Make it focusable for accessibility
            />
        </div>
    );
};

export default Search;