import React from 'react';
import 'antd/dist/antd.css';
import { Moment } from 'moment';
import { DatePicker as AntDatePicker } from 'antd';
import type { DatePickerProps as AntDatePickerProps } from 'antd';
import { CALENDAR } from '../../assets';
import { setClassName } from '../../utils/setClassName';
import Space from './Space';
import { Spacing } from '../../enums/spacing';


export interface DatePickerProps {
    caption?: string;
    selectedDate?: Moment | null;
    onDateChange?: (date: Moment | null) => void;
    min?: Date
    max?: Date
}

const componentName = 'Datepicker'
const n = setClassName(componentName)

const DatePicker = (props: DatePickerProps) => {

    const dateFormat = 'MM.DD.YYYY';
    const customFormat: AntDatePickerProps['format'] = (value) => `Start: ${value.format(dateFormat)}`;

    return (
        <div className={componentName}>
            {props.caption && <div className={"joinr-form-subtitles"}>{props.caption}</div>}

            <AntDatePicker
                disabledDate={(d) => {
                    if (props.min) {
                        if (props.max) {
                            return d.isBefore(props.min) || d.isAfter(props.max)
                        }
                        return d.isBefore(props.min)
                    }
                    return false
                }} clearIcon={null}
                className={n('input')}
                dropdownClassName={n('calendar')}
                format={customFormat}
                onChange={props.onDateChange}
                value={props.selectedDate}
                bordered={true}
                size={"large"}
                suffixIcon={<img src={CALENDAR} className={n('suffix-icon')}></img>}
            />
        </div>
    );
}

export default DatePicker