const ELLIPSIS = require('./ellipsis.svg')
const UPLOAD = require('./upload.svg')
const USER = require('./user.svg')
const TOOLTIP = require('./tooltip.svg')
const DELETE = require('./delete.svg')
const PENCIL = require('./pencil.svg')
const CHEVRON = require('./chevron.svg')
const USER_PLACEHOLDER = require('./user_placeholder.png')
const CALENDAR = require('./calendar.svg')


export {
    ELLIPSIS,
    UPLOAD,
    USER,
    TOOLTIP,
    USER_PLACEHOLDER,
    DELETE,
    CHEVRON,
    PENCIL,
    CALENDAR
}