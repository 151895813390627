import * as LocalStorage from 'local-storage'        //https://github.com/bevacqua/local-storage

export const KEY_AUTHENTICATION = "us.joinr.dashboard.persistent.AUTHENTICATION";
export const KEY_SESSION = "de.joinr.dashboard.persistent.auth.SESSION";

export interface Authentication {
    accessToken: string,
    expiresAt: string
}

export function remove(key: string) {
    LocalStorage.remove(key);
}
export function clearAll() {
    LocalStorage.clear();
}

export function save(key: string, value: string) {
    LocalStorage.set(key, value);
}
export function get(key: string): string | undefined {
    return LocalStorage.get(key);
}

export function watch(key: string, onChange: (value: string, oldValue: string) => void) {
    LocalStorage.on(key, onChange);
}
export function unwatch(key: string, onChange: (value: string, oldValue: string) => void) {
    LocalStorage.off(key, onChange);
}