import React from 'react'
import { Spacing } from '../../enums/spacing'
import { setClassName } from '../../utils/setClassName'

interface SpaceProps {
    height?: Spacing.small | Spacing.medium | string;
    maxWidth?: string;
}

const Space = (props: SpaceProps) => {
    const componentName = "Space"
    const n = setClassName(componentName)

    return (
        <div className={componentName} style={{
            height: props.height || Spacing.medium,
            maxWidth: props.maxWidth || '100%',
            width: '100%'

        }}></div>
    )
}
export default Space