// TODO: fix this import
import { Position, Waypoint } from 'joinr-dashboard-client-api/lib/api/models/Waypoint';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface FormatToJoinrWaypointResult {
    formatToJoinrWaypoint: (data: google.maps.places.PlaceResult, waypoint: Waypoint) => Waypoint | undefined;
}

export const getLatAndLong = (geo: google.maps.places.PlaceGeometry): Position => {
    const id = uuidv4().replace(/-/g, '');

    const position = {
        _id: id,
        latitude: 1,
        longitude: 1
    }
    const latitude = geo.location?.lat
    const longitude = geo.location?.lng

    if (typeof latitude === 'function') {
        position.latitude = latitude()
    }
    if (typeof longitude === 'function') {
        position.longitude = longitude()
    }
    return position
}

export const useWaypointFormatter = (): FormatToJoinrWaypointResult => {
    const formatToJoinrWaypoint = useCallback((data: google.maps.places.PlaceResult, waypoint: Waypoint) => {
        if (!data.address_components) return;

        const street = data.formatted_address?.split(',')[0];
        const locality = data.address_components.find(obj => obj.types.includes('locality'));
        const admin_1 = data.address_components.find(obj => obj.types.includes('administrative_area_level_1'));
        const country = data.address_components.find(obj => obj.types.includes('country'));

        const values = {
            street: street,
            city: `${locality?.long_name || ''}${(locality?.long_name && admin_1?.short_name === admin_1?.long_name) ?
                `, ${admin_1?.long_name}` :
                (!locality?.long_name ? admin_1?.long_name : '')}`,
            state: (admin_1?.short_name.length === 2) ? admin_1?.long_name : undefined,
            country: country?.long_name
        };

        Object.keys(values).forEach(key => {
            if (values[key] === undefined) {
                delete values[key];
                delete waypoint[key];
            }
        });

        const formatted = Object.assign(waypoint, values) as Waypoint;
        const latAndLong = getLatAndLong(data.geometry!);
        return { ...formatted, position: latAndLong! };
    }, []);

    return { formatToJoinrWaypoint };
};