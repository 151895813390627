const ACCOUNT = require('./account_solid.svg')
const ACCOUNT_OUTLINE = require('./account_outline.svg')

const CONTACT = require('./contact_solid.svg')
const CONTACT_OUTLINE = require('./contact_outline.svg')

const GROUP = require('./groups_solid.svg')
const GROUP_OUTLINE = require('./groups_outline.svg')

const LOGOUT = require('./logout.svg')

const HOME = require('./overview_solid.svg')
const HOME_OUTLINE = require('./overview_outline.svg')

const GROUP_CREATE = require('./creategroup_solid.svg')
const GROUP_CREATE_OUTLINE = require('./creategroup_outline.svg')

const GROUP_JOIN = require('./group_join.svg')

export {
    ACCOUNT,
    ACCOUNT_OUTLINE,

    CONTACT,
    CONTACT_OUTLINE,

    GROUP_CREATE,
    GROUP_CREATE_OUTLINE,

    GROUP,
    GROUP_OUTLINE,

    HOME,
    HOME_OUTLINE,

    GROUP_JOIN,
    LOGOUT,
}