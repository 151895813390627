// fetchGroups.ts
import { EventEmitter } from "events";

class FetchSpecificGroup extends EventEmitter {
    fetchAndWaitOnce(): Promise<void> {
        return new Promise((resolve) => {
            const handleFetchComplete = () => {
                resolve();
            };
            this.once('fetchComplete', handleFetchComplete);
            this.emit('fetch');
        });
    }
}

export const fetchSpecificGroup = new FetchSpecificGroup();