
import JoinrClientAPI, { getAllGroups, getDefaultApiConfig, getUserMe, Group, JoinrClientAPIConsumer, updateUserMe, User } from 'joinr-dashboard-client-api';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router';
import { isSessionEqual, getSession, isSessionValid, clearSession } from './api/SessionCompat';
import { ACCOUNT, ACCOUNT_OUTLINE, CONTACT, CONTACT_OUTLINE, GROUP, GROUP_CREATE, GROUP_CREATE_OUTLINE, GROUP_OUTLINE, HOME, HOME_OUTLINE, LOGOUT } from './assets';
import ModalCreateGroup from './components/modals/ModalCreateGroup';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { UserContextProvider } from './context/user';
import GroupRouting from './GroupRouting';
import Account from './pages/Account';
import Contacts from './pages/Contacts';
import Groups from './pages/Groups';
import Login from './pages/Login';
import Overview from './pages/Overview';
import { setClassName } from './utils/setClassName';
import NotFound from './pages/NotFound';
import { fetchUserMe } from './emitters/fetchUserMe';
import { errorToString } from './api/errors';
import { fetchSpecificGroup } from './emitters/fetchGroups';
import useFetchContacts from './hooks/useFetchContacts';

export const USE_DEBUG_ENVIRONMENT = false
export const API_CONFIG = getDefaultApiConfig(USE_DEBUG_ENVIRONMENT)

export interface RenderPageRedux {
    path: string;
    header?: React.JSX.Element
    page: React.JSX.Element
}

function RequireAuth() {
    const location = useLocation();

    if (!isSessionValid()) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return <Outlet />;
}
const App = () => {
    const componentName = "App"
    const n = setClassName(componentName)
    const [user, setUser] = useState<User>()
    const [ModalCreateGroupVisibility, setModalCreateGroupVisibility] = useState<boolean>(false)
    const [groups, setGroups] = useState<Array<Group>>([])
    const [loading, setLoading] = useState<boolean>(true)

    const session = getSession()!

    const { contacts, contactsLoading } = useFetchContacts(API_CONFIG, session);

    useEffect(() => {
        fetchUserMe.on('fetch', async () => {
            await getUserMe(API_CONFIG, session).then(resp => {
                setUser(resp)
            })
            fetchUserMe.emit('fetchComplete');
        });

        return () => {
            fetchUserMe.removeAllListeners('fetch');
        };
    }, [])

    const fetchAllGroups = () => {
        setLoading(true)
        const session = getSession()
        getAllGroups(API_CONFIG, session!).then(resp => {
            console.log(resp)
            setGroups(resp)
            setLoading(false)
        })
            .catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchSpecificGroup.on('fetch', () => {
            fetchAllGroups()
        })
        fetchSpecificGroup.emit('fetch')

        return () => {
            fetchSpecificGroup.removeAllListeners('fetch');
        };
    }, [])

    // Set user
    useEffect(() => {
        if (session?.getId()) {
            getUserMe(API_CONFIG, session).then((resp) => {
                setUser(resp)
            })
        } else {
            setUser(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session?.getId()])

    const renderRoute = ({ path, header, page }: RenderPageRedux) => {
        return <>
            <Route
                path={path}
                element={
                    <div className={n('sidebar-header-wrapper')}>
                        {SidebarItems}
                        <div className={n('header-main-wrapper')}>
                            {header || <Header />}
                            <div className='main-content'>
                                {page}
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    }

    const SidebarItems = <Sidebar items={[
        {
            icon: {
                main: HOME,
                alt: HOME_OUTLINE
            },
            text: 'overview',
            page: '/'
        },
        {
            icon: {
                main: CONTACT,
                alt: CONTACT_OUTLINE
            },
            text: 'contacts',
            page: 'contacts'
        },
        {
            icon: {
                main: GROUP,
                alt: GROUP_OUTLINE
            },
            text: 'groups',
            page: 'groups',
            subItems: [
                {
                    text: 'create group',
                    page: 'groups',
                },
                {
                    text: 'join group',
                    page: 'groups',
                },
                {
                    text: 'share group',
                    page: 'groups',
                },
            ]
        },
        {
            icon: {
                main: ACCOUNT,
                alt: ACCOUNT_OUTLINE
            },
            text: 'account',
            page: 'account'
        },
        {
            icon: {
                main: GROUP_CREATE,
                alt: GROUP_CREATE_OUTLINE
            },
            text: 'create group',
            onClick: () => {
                setModalCreateGroupVisibility(true)
            }
        },
        {
            icon: {
                main: LOGOUT
            },
            text: 'logout',
            page: 'login',
            onClick: () => {
                clearSession()
            }
        }
    ]} />

    return (
        <UserContextProvider value={{ user: user! }}>
            <JoinrClientAPI debugEnvironment={USE_DEBUG_ENVIRONMENT}>
                <JoinrClientAPIConsumer>
                    {context => {
                        if (!isSessionEqual(context.session)) {
                            context.setSession(getSession())
                        }
                        return <></>
                    }}
                </JoinrClientAPIConsumer>
                <Routes>
                    <Route
                        path='login'
                        element={<>
                            <div style={{ height: "100vh" }}>
                                <Login />
                            </div>
                        </>}
                    />

                    <Route element={<RequireAuth />}>
                        <Route path='/' element={
                            <>
                                {/* CREATE GROUP */}
                                <ModalCreateGroup
                                    visible={ModalCreateGroupVisibility}
                                    onVisibleChange={(visiblility) => setModalCreateGroupVisibility(visiblility)}
                                    onCancelClick={() => { setModalCreateGroupVisibility(false) }}
                                />
                                <Outlet />
                            </>
                        }>
                            {renderRoute({
                                path: '/',
                                page: <Overview />
                            })}
                            {renderRoute({
                                path: 'contacts',
                                page: <Contacts
                                    loading={contactsLoading}
                                    contacts={contacts}
                                    apiConfig={API_CONFIG}
                                    session={session}
                                />
                            })}
                            {renderRoute({
                                path: 'groups',
                                page: <Groups
                                    loading={loading}
                                    groups={groups}
                                    apiConfig={API_CONFIG}
                                    session={session}
                                />
                            })}
                            {renderRoute({
                                path: 'account',
                                page: < Account
                                    apiConfig={API_CONFIG}
                                    session={session}
                                    user={user!}
                                />
                            })}

                            {/* TODO: REMOVE */}
                            {/* {renderRoute({
                                path: 'testing',
                                page: <Testing />
                            })} */}

                            {/* Special case since header is rendered inside GroupRouting */}
                            <Route
                                path='group/:id/*'
                                element={
                                    <>
                                        <GroupRouting
                                            apiConfig={API_CONFIG}
                                            session={session}
                                            renderRoute={renderRoute}
                                        />
                                    </>
                                }
                            />

                            {renderRoute({
                                path: '*',
                                page: <NotFound />
                            })}
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </JoinrClientAPI>
        </UserContextProvider>
    )
}

export default App

