import { Form, Input as AntInput, Tooltip } from 'antd';
import TextArea, { TextAreaRef } from 'antd/lib/input/TextArea';
import { LiteralUnion } from 'antd/lib/_util/type';
import { Rule } from 'rc-field-form/lib/interface';
import React, { ReactElement, useRef, KeyboardEvent } from 'react';
import { TOOLTIP } from '../../assets';
import { setClassName } from '../../utils/setClassName';

export interface InputProps {
    onInputChange: (text: string) => void
    onEnterPress?: () => void // New prop for handling Enter key
    className?: string;
    placeholder?: string;
    name?: string;
    rules?: Rule[]
    type?: LiteralUnion<'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week', string>;
    icon?: ReactElement<any>
    title?: string
    titleColor?: string;
    color?: string;
    titleBold?: boolean;
    inputValue?: string;
    error?: string | null;
    info?: string;
    disabled?: boolean;
    width?: string;
    ref?: any;
    autocomplete?: boolean;
    imgRight?: string;
    defaultValue?: string;
    textArea?: boolean;
    textAreaEditable?: boolean;
    textAreaPlaceholder?: string;
    onTextAreaResize?: () => void;
}

const Input = (props: InputProps) => {
    const componentName = "Input"
    const n = setClassName(componentName)
    const textareaRef = useRef<TextAreaRef>(null);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && props.onEnterPress) {
            e.preventDefault();
            props.onEnterPress();
        }
    };

    return (
        <div style={{ width: props.width ? props.width : '100%' }} className={`${componentName} ${props.className}`}>
            {props.title &&
                <div className={n('title-wrapper')}>
                    <div style={{ color: props.titleColor || '#ee002e' }} className={'joinr-form-subtitles'}>{props.title}</div>
                    {props.info &&
                        <Tooltip
                            color={'rgba(64, 61, 62, 1)'}
                            overlayStyle={{ whiteSpace: 'pre-line' }}
                            title={props.info}
                            trigger="hover">
                            <img className={n('tooltip')} src={TOOLTIP} alt="" />
                        </Tooltip>
                    }
                </div>
            }
            <Form.Item
                name={props.name}
                rules={props.rules}
            >
                {props.textArea
                    ? <TextArea
                        color={`${props.color} !important`}
                        ref={textareaRef}
                        className={n('text-area')}
                        onChange={(e) => props.onInputChange(e.target.value)}
                        onKeyPress={handleKeyPress}
                        value={props.inputValue}
                        disabled={!props.textAreaEditable}
                        placeholder={props.textAreaPlaceholder}
                        defaultValue={props.defaultValue}
                        rows={4}
                    />
                    : <AntInput
                        color={`${props.color} !important`}
                        disabled={props.disabled}
                        className={n('input')}
                        prefix={props.icon}
                        type={props.type}
                        placeholder={props.placeholder}
                        onChange={(e) => props.onInputChange(e.target.value)}
                        onKeyPress={handleKeyPress}
                        value={props.inputValue}
                        defaultValue={props.defaultValue}
                    />
                }
                {props.imgRight
                    ? <img className={n('img-right')} src={props.imgRight} alt="delete" onClick={() => { console.log('ok') }} />
                    : null
                }
            </Form.Item>
            {props.error
                ? <div className={n('error')}>{props.error}</div>
                : null
            }
        </div>
    )
}

export default Input