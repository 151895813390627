import React from 'react'
import { Button as AntButton, Tooltip } from 'antd';
import { setClassName } from '../../utils/setClassName';

export interface ButtonProps {
    onClick: () => void;
    className?: string;
    text?: string;
    htmlType?: "button" | "submit" | "reset"
    loading?: boolean;
    tooltipError?: string;
    hide?: boolean;
    disabled?: boolean;
    style?: "cancel" | "save"
}


const Button = ({ style, className, ...props }: ButtonProps) => {
    const componentName = "Button"
    const n = setClassName(componentName)

    return (
        <Tooltip title={props.tooltipError} trigger={'click'}>
            <AntButton
                className={`${className + ' '} Button ${style ? n(style) : ''}`}
                style={{ display: props.hide ? 'none' : 'block' }}
                type={props.disabled ? 'ghost' : 'primary'}
                htmlType={props.htmlType}
                onClick={props.onClick}
                loading={props.loading}
            >
                {props.text || style}
            </AntButton>
        </Tooltip>
    )
}

export default Button