import React from 'react';
import { useNavigate } from 'react-router-dom';
import { setClassName } from '../utils/setClassName';
import { Button } from '../components/generic';

const NotFound = () => {
    const navigate = useNavigate();
    const pageName = "NotFound"
    const n = setClassName(pageName);

    return (
        <div className={pageName}>
            <div className={n('content')}>
                <h1 className={n('title')}>404</h1>
                <h2 className={n('subtitle')}>Page Not Found</h2>
                <p className={n('message')}>
                    The page you are looking for does not exist or has been moved.
                </p>
                <div className={n('buttons')}>
                    <Button
                        text={'Go Home'}
                        onClick={() => navigate('/')}
                    />
                    <Button
                        text={'Go Back'}
                        onClick={() => navigate(-1)}
                        style={'cancel'}
                    />
                </div>
            </div>
        </div>
    );
};

export default NotFound;