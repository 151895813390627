import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import { compose, withProps } from "recompose"
import { GoogleMap, Libraries, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import { GroupDay } from 'joinr-dashboard-client-api';
import _ from 'lodash';
import { WAYPOINT } from '../assets/img/roadbook';
import Button from '../components/generic/Button';
import Modal from '../components/generic/Modal';
import RoadbookDay from '../components/RoadbookDay';
import Title from '../components/generic/Title';
import Space from '../components/generic/Space';
import { GroupPage } from '../models/grouppage';
import { API_KEY } from '../utils/maputils';
import { setClassName } from '../utils/setClassName';
import { useDay } from '../context/roadbook';
import useUpdateLogger from '../hooks/useUpdateLogger';

type RoadbookProps = GroupPage

const Roadbook = (props: RoadbookProps) => {
    const { editMode, setEditMode, isGroupOwner, group, refs, addNewDay, dirtyDays, getDays, cleanDays, getAllGroupDaysLoading, setActiveGroupDay, activeGroupDay, saveChangesModal, setSaveChangesModal, confirmChangesLoading, saveButtonDisabled: saveButtonDisabled, uploadImages } = useDay();

    const pageName = "Roadbook"
    const n = setClassName(pageName)
    const [addWaypointClicked, setAddWaypointClicked] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(false)



    const renderRightSide = () => {
        if (getAllGroupDaysLoading) {
            return <Spin className={n('spinner')} size="large" />
        }
        else if (dirtyDays.length < 1) {
            return <div className={n('no-days')}>
                This group has no routes yet.
                <br></br>
                <br></br>
                You can start adding routes by clicking the add day button above.
            </div>
        }
        else {
            return dirtyDays?.map((groupDay, index) => {
                return (
                    <RoadbookDay
                        key={groupDay.getId()}
                        ref={(ref) => {
                            refs.current[index] = ref;
                        }}
                        groupDay={groupDay}
                        index={index}
                        activeGroupDay={activeGroupDay}
                        onActiveGroupDayChange={(group: GroupDay | null) => {
                            if (!group) return
                            setActiveGroupDay(group)
                        }}
                        addWaypointClicked={addWaypointClicked}
                        editMode={editMode}
                    />
                )
            })
        }
    }

    const renderHeader = () => {
        if (!isGroupOwner()) return
        else return <>
            <Button
                className={n('add-day-btn')}
                text={'Add Day'}
                onClick={() => addNewDay()}
                hide={(!editMode)}
                disabled={loading}
            />
            {cleanDays && cleanDays.length < 1 && dirtyDays.length < 1
                ? null
                : <Button
                    className={n('add-waypoint-btn')}
                    text={'Add Waypoint'}
                    onClick={() => {
                        setAddWaypointClicked(!addWaypointClicked)
                    }}
                    hide={!editMode || !activeGroupDay || dirtyDays.length < 1}
                    disabled={loading}
                />}
            {
                <Space maxWidth='4.4rem' />
            }
            <Button
                className={n('cancel-btn')}
                text={'Cancel'}
                onClick={() => {
                    getDays()
                    setEditMode(false)
                }}
                hide={!editMode || (dirtyDays.length < 1) && (dirtyDays === cleanDays) || dirtyDays === cleanDays}
            />
            {
                cleanDays && cleanDays.length < 1 && dirtyDays.length < 1 ? null :
                    <Button
                        className={n('edit-btn')}
                        text={`${editMode ? 'Save Changes' : 'Edit'}`}
                        onClick={() => {
                            if (editMode) setSaveChangesModal(true)
                            else setEditMode(true)
                        }}
                        disabled={loading || saveButtonDisabled}
                    />
            }

        </>
    }

    useUpdateLogger('dirtyDays: ', dirtyDays)

    return (
        <div className={`${editMode ? 'edit-mode' : ''}`}>
            <div className={`${pageName} `}>
                <Modal
                    closeButton={!confirmChangesLoading}
                    outsideClickClose={!confirmChangesLoading}
                    title={'Save Changes'}
                    visible={saveChangesModal}
                    okButton={{
                        onClick: () => {
                            uploadImages()
                        },
                        loading: confirmChangesLoading,
                        text: 'Confirm'
                    }}
                    cancelButton={{
                        onClick: () => {
                            setSaveChangesModal(false)
                        },
                        text: 'Cancel',
                        disabled: confirmChangesLoading
                    }}
                >
                    <div className={n('')}>
                        Are you sure you want to save your changes?
                    </div>
                </Modal>

                {getAllGroupDaysLoading ? null :
                    <div className={n('header')}>
                        {renderHeader()}
                    </div>
                }

                <div className={n('main-content')}>
                    <div className={n('left')}>
                        <div className={n('map')}>
                            <Title
                                className={n('map-title')}
                                text={`Routes for ${group?.title}`}
                            />
                            <div className={n('editing-mode-text')}>
                                Editing Mode Active
                            </div>
                            <div className={n('map-fader')}>
                                <MyMapComponent />
                            </div>
                        </div>
                    </div>

                    <div className={n('right')}>
                        {renderRightSide()}
                    </div>
                </div>
            </div>
        </div>
    );
}
const libraries = ['geometry', 'drawing', 'places'] as Libraries
const MyMapComponent: React.FC = () => {
    const { editMode, extractWaypoints, bounds, coords, selectedGoogleWaypoint } = useDay();
    const mapRef = useRef<any>(null);
    const pageName = "Roadbook"
    const n = setClassName(pageName)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        libraries: libraries
    })

    useEffect(() => {
        if (mapRef.current && coords && bounds) {
            mapRef.current.fitBounds(bounds);
        }
    }, [coords, mapRef, bounds]);
    const renderWaypoints = () => {

        return extractWaypoints().map(waypoint => {
            if ((waypoint.position.latitude === 1) && (waypoint.position.longitude === 1)) return // remvove empty waypoints
            else return (
                <Marker
                    key={waypoint._id}
                    position={{ lat: waypoint.position.latitude, lng: waypoint.position.longitude }}
                />
            )
        })
    }


    const centerMap = useMemo(() => {
        const centerOfUSA = { lat: 39.8, lng: -98.5 }
        if (selectedGoogleWaypoint?.lat() && selectedGoogleWaypoint?.lng() && selectedGoogleWaypoint?.lat() !== 1) {
            return { lat: selectedGoogleWaypoint?.lat(), lng: selectedGoogleWaypoint?.lng() }
        }
        else return centerOfUSA
    }, [selectedGoogleWaypoint])

    if (!isLoaded) {
        return null;
    }

    return <GoogleMap
        center={centerMap}
        mapContainerClassName={n('map')}
        zoom={7}
        onLoad={(map) => {
            mapRef.current = map;
            if (mapRef.current && bounds) {
                mapRef.current.fitBounds(bounds);
            }
        }}
        options={{
            disableDefaultUI: editMode,
            draggable: !editMode
        }}>
        {renderWaypoints()}
        <Polyline
            path={coords}
            options={{
                strokeColor: "#ff2527",
                strokeOpacity: 1,
                strokeWeight: 2,
                icons: [{
                    icon: WAYPOINT,
                    offset: '0',
                    repeat: '10px'
                }],
            }}
        />
    </GoogleMap>
};


export default Roadbook