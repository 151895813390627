import React, { useContext, useEffect } from 'react';
import { setClassName } from '../utils/setClassName';
import Dropdown from './generic/Dropdown';
import { Group } from 'joinr-dashboard-client-api';
import { UserContext } from '../context/user';
import { useLocation } from 'react-router-dom';

export interface Tab {
    tab_name: string,
    onClick?: () => void
}

interface GroupDetailsNavbarProps {
    tabs: Tab[]
    activeTab?: string | undefined;
    onActiveTabChange?: (tab: string | undefined) => void;
    group: Group;
}

const GroupDetailsNavbar = (props: GroupDetailsNavbarProps) => {
    const componentName = "GroupDetailsNavbar"
    const n = setClassName(componentName)
    const { user } = useContext(UserContext)
    const location = useLocation();

    const isUserGroupLeader = () => {
        if (props.group && props.group.owner && user) {
            return props.group.owner.getId() == user.getId()
        }
    }

    useEffect(() => {
        if (props.tabs.length > 0) {
            // Map routes to tab names
            const routeToTabName: { [key: string]: string } = {
                '': 'Group Details',
                'roadbook': 'Roadbook',
                'members': 'Members',
                'gallery': 'Gallery',
                'admin': 'Admin'
            };

            // Get the current route segment
            const currentRoute = location.pathname.split('/').pop() || '';

            // Find the corresponding tab name
            const activeTab = routeToTabName[currentRoute] || props.tabs[0].tab_name;

            props.onActiveTabChange?.(activeTab);
        }
    }, [location.pathname, props.tabs]);

    const isGroupDetailsPage = () => {
        // This regex matches /group/ followed by exactly one segment of alphanumeric characters
        const groupPageRegex = /^\/group\/[a-zA-Z0-9]+$/;
        return groupPageRegex.test(window.location.pathname);
    };

    return (
        <div className={componentName}>
            {props.tabs.map(tab => {
                return (
                    <div
                        key={tab.tab_name}
                        className={n(`option ${props.activeTab === tab.tab_name ? n('selected') : ''}`)}
                        onClick={() => {
                            tab.onClick && tab.onClick()
                            props.onActiveTabChange && props.onActiveTabChange(tab.tab_name)
                        }
                        }
                    >{tab.tab_name}
                    </div>
                )
            })}
        </div>
    )
}

export default GroupDetailsNavbar