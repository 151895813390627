import React from 'react'
import { setClassName } from '../utils/setClassName'

interface HeaderProps {

}

const Header = (props: HeaderProps) => {
    const pageName = "Overview"
    const n = setClassName(pageName)

    return (
        <div className={pageName}>Overview</div>
    )
}

export default Header