import { ApiConfig, getContacts, Session, User } from "joinr-dashboard-client-api";
import { useCallback, useEffect, useState } from "react";
import { errorToString } from "../api/errors";
import * as toastr from 'toastr';
import { fetchContacts } from "../emitters/fetchContacts";

interface UseFetchContactsResult {
    contacts: User[];
    contactsLoading: boolean;
}

const useFetchContacts = (apiConfig: ApiConfig, session: Session): UseFetchContactsResult => {
    const [contacts, setContacts] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);

    // Call the endpoint
    const fetchContactsData = useCallback(() => {
        setLoading(true);
        return getContacts(apiConfig, session)
            .then(resp => {
                setContacts(resp.users);
                return resp;
            })
            .catch(err => {
                errorToString(err).forEach(err => toastr.error(err));
            })
            .finally(() => {
                setLoading(false);
            });
    }, [session, apiConfig]);

    // Setup Emitter
    useEffect(() => {
        fetchContactsData();

        fetchContacts.on('fetch', async () => {
            await fetchContactsData();
            fetchContacts.emit('fetchComplete');
        });

        // Remove the listener
        return () => {
            fetchContacts.removeAllListeners('fetch');
        };
    }, []);

    return { contacts, contactsLoading: loading };
};

export default useFetchContacts;