const LOGO_DARK = require('./dark.svg')
const LOGO_LIGHT = require('./light.svg')
const ICON_DARK = require('./icon_dark.svg')
const ICON_LIGHT = require('./icon_light.svg')
const LOGO_TYPEFACE_DARK = require('./typeface_dark.svg')
const LOGO_TYPEFACE_WHITE = require('./typeface_light.svg')

export {
    LOGO_DARK,
    LOGO_LIGHT,
    ICON_DARK,
    ICON_LIGHT,
    LOGO_TYPEFACE_DARK,
    LOGO_TYPEFACE_WHITE
}