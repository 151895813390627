export enum APIErrorType {
    InvalidParameters = "InvalidParametersError",
    InvalidSubscriptionError = "InvalidSubscriptionError",
    PaymentInfoMissingError = "PaymentInfoMissingError",
    AlreadyExists = "ResourceAlreadyExists",
    InvalidGoogleToken = "InvalidGoogleToken",
    InvalidFacebookToken = "InvalidFacebookToken",
    InvalidLogin = "InvalidLoginError",
    ResourceNotFound = "ResourceNotFound",
    NotAccessibleError = "NotAccessibleError",
    UnknownError = "UnknownError",
    NetworkError = "NetworkError",
    MissingAuthorizationError = "MissingAuthorizationError",
    SessionNotFoundError = "SessionNotFoundError",
    DownloadLimitReachedError = "DownloadLimitReachedError",
    NoWeddingFoundError = 'NoWeddingFoundError'
}

export enum SubscriptionInfo {
    'None' = 0,
    'Standard' = 1
}


export function errorToString(err: { type?: string, displayMessage: string, message?: string }, defaultMessage = 'An unhandled error occurred. Please try again later.'): Array<string> {
    if (err.message) {
        return [err.message]
    }
    else if (err.displayMessage) {
        return [err.displayMessage]
    } else {
        return [defaultMessage]
    }
}

export function snakeToPascal(string: string) {
    function _upperFirst(string: string) {
        return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
    }
    return string.split('_').map((str) => {
        return _upperFirst(
            str.split('/')
                .map(_upperFirst)
                .join('/'));
    }).join(' ');
}