const MEMBER_COUNT = require('./member-count.svg')
const COPY = require('./copy.svg')
const COPY_GREY = require('./copy_grey.svg')
const LISTVIEW_SELECTED = require('./listview_selected.svg')
const LISTVIEW_UNSELECTED = require('./listview_unselected.svg')
const OVERVIEW_SELECTED = require('./overview_selected.svg')
const OVERVIEW_UNSELECTED = require('./overview_unselected.svg')

export {
    MEMBER_COUNT,
    COPY,
    LISTVIEW_SELECTED,
    LISTVIEW_UNSELECTED,
    OVERVIEW_SELECTED,
    OVERVIEW_UNSELECTED,
    COPY_GREY,
}