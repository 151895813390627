import React from 'react';
import { Modal as AntModal } from 'antd';
import { setClassName } from '../../utils/setClassName';
import { CHEVRON } from '../../assets';
import Button, { ButtonProps } from './Button';

// HOW TO USE: This component is to be imported INSIDE other non-generic modal components
// Wrap the non-generic modals jsx with this component so you can use those functions for the Modal onClick

export interface ModalProps {
    changeVisible?: (bool: boolean) => void;
    visible: boolean;
    title?: string;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    closeButton?: boolean;
    outsideClickClose?: boolean;
    okButton: ButtonProps;
    cancelButton?: ButtonProps;
}

const Modal = (props: ModalProps) => {
    const componentName = "Modal"
    const n = setClassName(componentName)

    const setVisibleFalse = () => {
        props.changeVisible && props.changeVisible(false)
    }

    return (
        <AntModal
            closable={props.closeButton || false}
            maskClosable={props.outsideClickClose}
            className={`Modal ${props.className}`}
            title={props.title}
            open={props.visible}
            onCancel={() => {
                if (props.cancelButton?.disabled) return
                // props.cancelButton?.onClick()
                setVisibleFalse()
            }}
            footer={[
                <Button
                    key={props.cancelButton?.onClick.toString() || "Cancel"}
                    onClick={() => {
                        if (props.cancelButton) {
                            props.cancelButton.onClick()
                        }
                        setVisibleFalse()
                    }}
                    style={"cancel"}
                />,
                <Button
                    key={props.okButton.onClick.toString()}
                    {...props.okButton}
                />
            ]}
        >
            <div className={n('back-button')} onClick={() => {
                if (props.cancelButton?.disabled) return
                props.changeVisible &&
                    props.changeVisible(false)
            }}>
                <img className={n('chevron')} src={CHEVRON} alt="" />
                back
            </div>
            <div className={n('description')}>
                {props.description}
            </div>
            {props.children}
        </AntModal>
    );
};

export default Modal