import React, { useContext, useEffect, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { GroupPage } from '../models/grouppage'
import { setClassName } from '../utils/setClassName'
import Space from '../components/generic/Space'
import { Spacing } from '../enums/spacing'
import { COPY_GREY } from '../assets/img/groups'
import { copyToClipboard } from '../utils/clipboard'
import SelectLeader from '../components/SelectLeader'
import { User, deleteGroup as deleteGroupEndpoint, updateGroup } from 'joinr-dashboard-client-api'
import { Spin, Switch, UploadFile } from 'antd'
import { DELETE, HOME, PLACEHOLDER_GROUP_CARD, UPLOAD } from '../assets'
import { Button } from '../components/generic'
import { BLUETOOTH, BROADCAST, PADLOCK, SPEED, TRASH } from '../assets/img/admin'
import { fetchSpecificGroup } from '../emitters/fetchGroups'
import useUpdateLogger from '../hooks/useUpdateLogger'
import { Group } from 'joinr-dashboard-client-api'
import { getBase64 } from '../utils/base64'
import Dragger from 'antd/lib/upload/Dragger'
import Setting from '../components/generic/Setting'
import { getSession } from '../api/SessionCompat'
import { API_CONFIG } from '../App'
import { fetchChangeGroupOwner } from '../emitters/fetchChangeGroupOwner'
import { errorToString } from '../api/errors'
import * as toastr from 'toastr'
import { UserContext } from '../context/user'
import { useNavigate } from 'react-router'
import Modal from '../components/generic/Modal'
import { useGalleryUpload } from '../hooks/useGalleryUpload'

interface AdminProps extends GroupPage {
}

const pageName = 'Admin'
const n = setClassName(pageName)

const Admin = (props: AdminProps) => {
    const { handleGalleryUpload, uploadBtnLoading, setUploadBtnLoading } = useGalleryUpload(API_CONFIG, props.groupId);

    const { user } = useContext(UserContext)
    const navigate = useNavigate()

    const [leader, setLeader] = useState<User | undefined>()
    const [isPublic, setIsPublic] = useState<boolean | undefined>(false)
    const [isBluetooth, setIsBluetooth] = useState<boolean | undefined>(false)
    const [enabledFeatures, setEnabledFeatures] = useState<string[]>([])
    const [isShareSpeed, setIsShareSpeed] = useState<boolean | undefined>(false)
    const [saveLoading, setSaveLoading] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<string>()
    const [file, setFile] = useState<File | null>(null)
    const [deleteModalVis, setDeleteModalVis] = useState<boolean>(false)


    useEffect(() => {
        props.onActiveTabChange &&
            props.onActiveTabChange('Admin')
    }, [])

    useEffect(() => {
        if (!props.group) return
        if (!props.group.owner) return
        if (!user) return
        if (!user.getId()) return

        if (props.group?.owner?.getId() !== user.getId()) {
            navigate(`/group/${props.group.getId()}`)
            props.onActiveTabChange &&
                props.onActiveTabChange('Group Details')
        }
    }, [props.group?.owner])

    const setInitialValues = (group: Group) => {
        setLeader(group!.owner)
        setIsPublic(group!.isPublic)
        setIsBluetooth(group!.isBluetoothEnabled())
        setIsShareSpeed(group!.shareSpeed())
        setEnabledFeatures(group!.enabledFeatures)
        setPreviewImage(group.previewImage?.getImageUrl512x())
    }

    // set initial values
    useEffect(() => {
        if (!props.group) return
        setInitialValues(props.group)
    }, [props.group])

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!) as (string | undefined);
        }
        setPreviewImage(file.url || file.preview)
    };

    const updateGroupDetails = async () => {
        setSaveLoading(true)
        if (!props.group) return

        updateGroup(API_CONFIG, getSession()!, props.group.getId()!, {
            group: {
                title: props.group.title,
                bluetooth_enabled: isBluetooth,
                // enabled_features: ['chat_broadcast'],
                share_speed: isShareSpeed,
                secret_word: props.group.secretWord,
                group_description: props.group.description,
                public: isPublic,
            }
        })
            .then((resp) => {
                if (file) {
                    return handleGalleryUpload(file, true)
                }
            })
            .then(() => {
                // All success actions after both operations complete
                leader && fetchChangeGroupOwner.emit('fetch')
                toastr.success('Group successfully updated')
                fetchSpecificGroup.emit('fetch')
            })
            .catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
            })
            .finally(() => {
                setSaveLoading(false)
            })
    }

    const deleteGroup = () => {
        if (!props.group) return;
        if (!props.group.getId()) return;
        if (!user) return;
        if (!user.getId()) return;
        setDeleteLoading(true)
        deleteGroupEndpoint(API_CONFIG, getSession()!, props.group!.getId()!).then(resp => {
            toastr.success(`${props.group!.title} was successfully deleted`)
            navigate('/groups/')
        }).catch(err => {
            errorToString(err).forEach(err => toastr.error(err))
        })
            .finally(() => {
                setDeleteLoading(false)
            })
    }
    return (
        <div className={pageName}>
            {
                !props.group ?
                    <Spin size="large" />
                    : <>
                        <div className={n('top')}>
                            <div className={n('left')}>
                                <div className={n('image')} style={saveLoading ? { pointerEvents: "none" } : {}}>
                                    <img className={n('image-upload-icon')} src={UPLOAD} alt="" />
                                    <Dragger
                                        onChange={({ fileList }) => {
                                            handlePreview(fileList[0])
                                        }}
                                        accept='.png, .jpg'
                                        showUploadList={false}
                                        className="uploadBox"
                                        beforeUpload={(file) => {
                                            setFile(file)
                                            return false
                                        }}
                                        maxCount={1}
                                    >
                                        < div
                                            className={n('image-inner')}
                                            style={{
                                                backgroundImage: `url(${previewImage || props.group.previewImage?.getImageUrl512x() || PLACEHOLDER_GROUP_CARD})`
                                            }}
                                        >
                                        </div>
                                    </Dragger>
                                </div>
                            </div>
                            <div className={n('right')}>
                                <div
                                    className={n('delete')}
                                    onClick={() => {
                                        setDeleteModalVis(true)
                                    }}>
                                    <img className={n('delete-icon')} src={TRASH} alt="" />
                                    <div className={n('delete-text')} >
                                        Delete Group
                                    </div>
                                </div>

                                {/* GROUP NAME */}
                                <div className={'joinr-form-subtitles'}>Group Name</div>
                                <div className={n('group-title')}>{props.group?.title}</div>
                                <Space height={Spacing.input25} />

                                {/* LEADER */}
                                {
                                    props.group && <SelectLeader
                                        leader={leader}
                                        setLeader={setLeader}
                                        group={props.group}
                                        groupId={props.groupId}
                                    />
                                }

                                <Space height={Spacing.input25} />

                                {/* SECRET WORD */}
                                <div className={'joinr-form-subtitles'}>Secret Word</div>
                                <div className={n('secret-word flex pointer bm20')} onClick={() => copyToClipboard(props.group?.secretWord)}>
                                    <div className={n('secret-word-text form-display-text foggy-grey')}>{props.group?.secretWord}</div>
                                    <Space maxWidth={Spacing.input20} />
                                    <img src={COPY_GREY} alt="" />
                                </div>
                                <Space height={Spacing.input25} />

                            </div>
                        </div>
                        <Space height={'4rem'} />

                        <div className={n('Settings')}>
                            SETTINGS
                        </div>


                        <Space height={'3rem'} />
                        <Setting
                            icon={PADLOCK}
                            title="Private Group"
                            description="Lorem"
                            toggleswitch={{
                                checked: isPublic,
                                onChange: () => setIsPublic(!isPublic)
                            }}
                        />
                        <Space height={Spacing.input25} />
                        <Setting
                            icon={BLUETOOTH}
                            title="Bluetooth"
                            description="Lorem"
                            toggleswitch={{
                                checked: isBluetooth,
                                onChange: () => setIsBluetooth(!isBluetooth)
                            }}
                        />
                        {/* <Space height={Spacing.input25} /> */}
                        {/* <Setting
                            icon={BROADCAST}
                            title="Broadcast"
                            description="Lorem"
                            toggleswitch={{
                                checked: enabledFeatures.includes('chat_broadcast') ? true : false,
                                onChange: () => {
                                    let features = [...enabledFeatures];
                                    if (features.includes('chat_broadcast')) {
                                        features = features.filter(feature => feature !== 'chat_broadcast');
                                    } else {
                                        features.push('chat_broadcast');
                                    }
                                    setEnabledFeatures(features);
                                }
                            }}
                        /> */}
                        <Space height={Spacing.input25} />
                        <Setting
                            icon={SPEED}
                            title="Share Speed"
                            description="Lorem"
                            toggleswitch={{
                                checked: isShareSpeed,
                                onChange: () => setIsShareSpeed(!isShareSpeed)
                            }}
                        />

                        <Space height={"4rem"} />
                        <div className={n('bottom')}>
                            <Button
                                key="CancelButton"
                                text={"Cancel"}
                                onClick={async () => {
                                    setSaveLoading(true)
                                    await fetchSpecificGroup.fetchAndWaitOnce();
                                    setSaveLoading(false)
                                }}
                                style={'cancel'}
                            />
                            <Button
                                key="SaveButton"
                                text={"Save"}
                                loading={saveLoading}
                                onClick={() => updateGroupDetails()}
                            />
                        </div>
                        <Modal
                            title={`DELETE GROUP`}
                            okButton={{
                                text: "Delete",
                                onClick: () => { deleteGroup() },
                                loading: deleteLoading
                            }}
                            visible={deleteModalVis}
                            changeVisible={setDeleteModalVis}
                            outsideClickClose={false}
                            description={`You are about to permanently delete group ${props.group.title}. Are you sure?`}
                        >
                        </Modal>
                    </>
            }
        </div >
    )
}

export default Admin