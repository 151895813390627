import { ApiConfig, getGroupById, Group, Session } from "joinr-dashboard-client-api";
import { useCallback, useEffect, useState } from "react";
import { errorToString } from "../api/errors";
import { fetchSpecificGroup } from "../emitters/fetchGroups";
import * as toastr from 'toastr'

interface UseGroupDataResult {
    group: Group | undefined;
    error: boolean;
    isLoading: boolean;
}

const useGroupData = (apiConfig: ApiConfig, session: Session, groupId: string): UseGroupDataResult => {
    const [group, setGroup] = useState<Group>();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const fetchGroup = useCallback(() => {
        setIsLoading(true);
        return getGroupById(apiConfig, session, groupId)
            .then(resp => {
                setGroup(resp);
                setError(false);
                return resp;
            })
            .catch(err => {
                setError(true);
                errorToString(err).forEach(err => toastr.error(err));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [groupId, session, apiConfig]);

    useEffect(() => {
        fetchGroup();

        fetchSpecificGroup.on('fetch', async () => {
            await fetchGroup();
            fetchSpecificGroup.emit('fetchComplete');
        });

        return () => {
            fetchSpecificGroup.removeAllListeners('fetch');
        };
    }, []);

    return { group, error, isLoading };
};

export default useGroupData