const SPEED = require('./speed.svg');
const BROADCAST = require('./broadcast.svg');
const BLUETOOTH = require('./bluetooth.svg');
const PADLOCK = require('./padlock.svg');
const TRASH = require('./trash.svg');

export {
    SPEED,
    BROADCAST,
    BLUETOOTH,
    PADLOCK,
    TRASH,
};