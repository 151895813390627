import { User } from 'joinr-dashboard-client-api'
import React, { useEffect, useRef, useState } from 'react'
import { API_CONFIG } from '../App'
import { CALENDAR, PLACEHOLDER_GROUP_CARD } from '../assets'
import Input from '../components/generic/Input'
import Modal from '../components/generic/Modal'
import { fetchSpecificGroup } from '../emitters/fetchGroups'
import { GroupPage } from '../models/grouppage'
import { setClassName } from '../utils/setClassName'
import Dropdown from '../components/generic/Dropdown'
import ModalEditGroup from '../components/modals/ModalEditGroup'
import { Spin } from 'antd'
import Space from '../components/generic/Space'
import { copyToClipboard } from '../utils/clipboard'
import { COPY_GREY } from '../assets/img/groups'
import { Spacing } from '../enums/spacing'
import moment from 'moment'

const pageName = 'GroupDetail'
const n = setClassName(pageName)

interface GroupDetailProps extends GroupPage {
}



const GroupDetail = (props: GroupDetailProps) => {
    const [editModalVis, setEditModalVis] = useState<boolean>(false)

    const renderEditEllipses = () => {
        return <div className={n('edit-ellipses')}>
            <Dropdown
                items={[
                    {
                        text: "Edit",
                        onClick: () => { setEditModalVis(true) }
                    }
                ]}
            />
        </div>
    }

    return (
        <div className={pageName}>
            {
                !props.group ?
                    <Spin size="large" />
                    : <>
                        {renderEditEllipses()}
                        {/* TOP OF FORM */}
                        <div className={n('top')}>

                            <div className={n('left')}>
                                < div
                                    className={n('image')}
                                    style={{
                                        backgroundImage: `url(${props.group.previewImage?.getImageUrl512x() || PLACEHOLDER_GROUP_CARD})`
                                    }}
                                >
                                </div>
                                <Space height={Spacing.input20} />
                                <div className={'joinr-form-subtitles'}>Group Leader</div>
                                <div className={n('form-display-text')}>{props.group.owner?.displayName}</div>
                            </div>

                            <div className={n('right')}>

                                {/* GROUP NAME */}
                                <div className={'joinr-form-subtitles'}>Group Name</div>
                                <div className={n('group-title')}>{props.group.title}</div>
                                <Space height={Spacing.input20} />

                                {/* SECRET WORD */}
                                <div className={'joinr-form-subtitles'}>Secret Word</div>
                                <div className={n('secret-word flex pointer bm20')} onClick={() => copyToClipboard(props.group?.secretWord)}>
                                    <div className={n('secret-word-text form-display-text foggy-grey')}>{props.group.secretWord}</div>
                                    <Space maxWidth={Spacing.input20} />
                                    <img src={COPY_GREY} alt="" />
                                </div>
                                <Space height={Spacing.input20} />

                                {/* TYPE OF EVENT */}
                                <div className={'joinr-form-subtitles'}>Type of Event</div>
                                <div className={n('form-display-text')}>{props.group.eventType}</div>
                                <Space height={Spacing.input20} />

                                {/* DURATION */}
                                <div className={n('')}>
                                    <div className={'joinr-form-subtitles'}>Duration</div>
                                    <div className={n('date flex')}>
                                        <div className={n('form-display-text foggy-grey')}>{"Start"}</div>
                                        <Space maxWidth={"1rem"} />
                                        <img src={CALENDAR} alt="calendar" />
                                        <Space maxWidth={"10rem"} />
                                        <div className={n('form-display-text')}>{moment(props.group.startTime).format('MM.DD.YYYY')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Space height={Spacing.input20} />

                        {/* DESCRIPTION */}
                        <div className={'joinr-form-subtitles'}>Description</div>
                        <div className={n('form-display-text')}>{props.group.description}</div>
                    </>
            }
            <ModalEditGroup
                okButton={{
                    text: "",
                    onClick: () => { return }
                }}
                visible={editModalVis}
                changeVisible={setEditModalVis}
                outsideClickClose={false}
                group={props.group}
                groupId={props.group?.getId() || ''}
                apiConfig={API_CONFIG}
                session={props.session}
            />
        </div >

    )
}

export default GroupDetail