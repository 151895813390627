import * as Store from './PersistentData'
import { Session } from 'joinr-dashboard-client-api';

export const KEY_SESSION = "de.joinr.dashboard.persistent.auth.SESSION";

export function setSession(session: Session) {
    Store.save(KEY_SESSION, JSON.stringify(session.getJSON()))
}

export function clearSession() {
    Store.remove(KEY_SESSION)
}

export function getSession(): Session | undefined {
    try {
        const serialized = Store.get(KEY_SESSION)
        if (serialized) {
            return new Session(JSON.parse(serialized))
        }
    }
    catch (err) {
        //
    }
    return undefined
}

export function isSessionValid(): boolean {
    try {
        const session = getSession()
        if (session) {
            return !session.isExpired()
        }
        // if(session) {
        // 	const expiresAt = new Date(session['expiresAt'])
        // 	if(expiresAt.getTime()>new Date().getTime()) {
        // 		return true
        // 	}
        // }
    }
    catch (err) {
        //
    }
    return false
}

export function isSessionEqual(session?: Session): boolean {
    var sessionEquals = false
    try {
        if (session && session.getId() === getSession()!.getId()) {
            sessionEquals = true
        }
    }
    catch (err) {
        //
    }
    return sessionEquals
}