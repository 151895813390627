import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import Button from './Button';
import Search from './Search';
import Dropdown from './Dropdown';
import { SEARCH } from '../../assets/img/header';
import { CHAT, FILTER } from '../../assets/img/contacts';
import { SortConfig, SortField, TableRow, UserTableProps } from '../../models/table';
import { getPageNumbers, renderSortItems, searchTable } from '../../utils/table';

const UserTable: React.FC<UserTableProps> = ({
    data,
    loading = false,
    pageName,
    onAddClick,
    showLeader = false,
    leaderInfo,
    actionItems = []
}) => {
    const n = (className: string) => `Table-${className} ${pageName}`;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState<SortConfig>({ field: 'name', ascending: true });
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const processedData = searchTable(data, searchTerm, sortConfig);

    const handleSort = (value: string) => {
        const [field, direction] = value.split(':');
        setSortConfig({
            field: field as SortField,
            ascending: direction === 'asc'
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (items: number) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const renderTableRow = (row: TableRow, index: number, actionItems: any[], n: (s: string) => string) => (
        <tr
            key={row.id}
            className={`${n(index % 2 ? 'row-odd' : 'row-even')} ${row.kicked || row.left ? n('row-inactive') : ''}`}
        >
            <td>
                <div className={n('user-container')}>
                    <img className={n('user-image')} src={row.image} alt="" />
                    <div className={n('user-name')}>
                        {row.name}
                        {(row.kicked || row.left) && (
                            <span className={n('status-tag')}>{row.kicked ? 'Kicked' : 'Left'}</span>
                        )}
                    </div>
                </div>
            </td>
            <td>{row.email}</td>
            <td>{row.city}</td>
            <td>{row.date}</td>
            <td>{!(row.kicked || row.left) && <img src={CHAT} alt="" />}</td>
            <td>
                {!(row.kicked || row.left) && (
                    <Dropdown items={actionItems.map(item => ({ text: item.text, onClick: () => item.onClick(row) }))} />
                )}
            </td>
        </tr>
    );

    // Calculate pagination values
    const totalPages = Math.ceil(processedData.length / itemsPerPage);
    const currentItems = processedData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (loading) return <Spin size="large" />;

    // Then in the return statement:
    return (
        <div className="Table">
            {loading ? (
                <Spin size="large" />
            ) : (
                <>
                    <div className={n('header')}>
                        <div className={n('header-controls')}>
                            {onAddClick && (
                                <Button
                                    className={n('add-button')}
                                    text={`+ Add ${pageName.slice(0, -1)}`}
                                    onClick={onAddClick}
                                />
                            )}
                            <div className={n('header-controls-right')}>
                                <Search
                                    value={searchTerm}
                                    onChange={handleSearch}
                                    icon={SEARCH}
                                    className={n('search-box')}
                                />
                                <Dropdown icon={FILTER} items={renderSortItems(handleSort)} />
                            </div>
                        </div>

                        {showLeader && leaderInfo && (
                            <div className={n('leader-container')}>
                                <div className={n('leader')}>
                                    <img className={n('user-image')} src={leaderInfo.image} alt="" />
                                    <div className={n('leader-name')}>{leaderInfo.name} (You)</div>
                                </div>
                                <div className={n('leader-text')}>Leader</div>
                            </div>
                        )}
                    </div>

                    <div className={n('table-wrapper')}>
                        {currentItems.length > 0 ? (
                            <table className={n('table')}>
                                <tbody>
                                    {currentItems.map((row, index) => renderTableRow(row, index, actionItems, n))}
                                </tbody>
                            </table>
                        ) : (
                            <div className={n('no-results')}>
                                There were no results found for your search.
                            </div>
                        )}
                    </div>

                    {processedData.length > 5 && (
                        <div className={n('pagination')}>
                            <div className={n('pagination-left')}>
                                <Dropdown
                                    paginationPage={itemsPerPage}
                                    items={[5, 10, 15].map(num => ({
                                        text: num.toString(),
                                        onClick: () => handleItemsPerPageChange(num)
                                    }))}
                                />
                            </div>
                            <div className={n('pagination-right')}>
                                <button
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className={n('pagination-button')}
                                >
                                    Previous
                                </button>

                                <div className={n('pagination-numbers')}>
                                    {getPageNumbers(totalPages, currentPage).map((pageNum, index) => {
                                        if (pageNum === '...') {
                                            return (
                                                <span
                                                    key={`ellipsis-${index}`}
                                                    className={n('pagination-ellipsis')}
                                                >
                                                    ...
                                                </span>
                                            );
                                        }

                                        return (
                                            <button
                                                key={`page-${pageNum}`}
                                                onClick={() => handlePageChange(pageNum as number)}
                                                className={`${n('pagination-button')} ${currentPage === pageNum ? n('active') : ''}`}
                                            >
                                                {pageNum}
                                            </button>
                                        );
                                    })}
                                </div>

                                <button
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                    className={n('pagination-button')}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default UserTable