import { InboxOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal as PreviewImageModal, Spin, Switch } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile } from 'antd/lib/upload/interface';
import { removeGroupGalleryImage } from 'joinr-dashboard-client-api';
import { PreviewImage } from 'joinr-dashboard-client-api';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import * as toastr from 'toastr';
import { errorToString } from '../api/errors';
import { getSession } from '../api/SessionCompat';
import { API_CONFIG } from '../App';
import Button from '../components/generic/Button';
import Modal from '../components/generic/Modal';
import { fetchGallery } from '../emitters/fetchGallery';
import { GroupPage } from '../models/grouppage';
import { setClassName } from '../utils/setClassName';
import Space from '../components/generic/Space';
import { useGalleryUpload } from '../hooks/useGalleryUpload';

interface GalleryProps extends GroupPage {
    gallery: PreviewImage[];
    loading: boolean;
}

type photo_id = string

const pageName = 'Gallery'
const n = setClassName(pageName)


const Gallery = (props: GalleryProps) => {
    const { handleGalleryUpload, uploadBtnLoading, setUploadBtnLoading } = useGalleryUpload(API_CONFIG, props.groupId);

    const [photos, setPhotos] = useState<PreviewImage[]>([])
    const [selectedImages, setSelectedImages] = useState<photo_id[]>([])
    const [fileList, setFileList] = useState<RcFile[] | null>()
    const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false)
    const [lightBoxIndex, setLightboxIndex] = useState<number>(0)
    const [selectMode, setSelectMode] = useState<boolean>(true)

    const [deleteBtnLoading, setDeleteBtnLoading] = useState<boolean>(false)

    // MODALS //
    const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false)

    const [previewTitle, setPreviewTitle] = useState<string>()
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()

    const getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!) as (string | undefined);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    };

    useEffect(() => {
        console.log(fileList)
        if (!fileList) return

        if (fileList.length > 10) {
            toastr.error('You have selected more than 10 images')
        }

    }, [fileList])

    useEffect(() => {
        props.onActiveTabChange &&
            props.onActiveTabChange('Gallery')
    }, [])

    useEffect(() => {
        setPhotos(props.gallery)
    }, [props.gallery])

    // Lightbox & select logic
    useEffect(() => {
        if (!selectMode) {
            setSelectedImages([])
            setLightboxIndex(lightBoxIndex || 0)
        }
    }, [selectMode])

    const handleSelectImage = (photo_id: string) => {
        if (selectedImages.includes(photo_id)) {
            const newSelectedImages = selectedImages.filter((image) => image !== photo_id)
            setSelectedImages(newSelectedImages)
        }
        else {
            const newSelectedImages = [...selectedImages, photo_id]
            setSelectedImages(newSelectedImages)
        }
    }

    const handleSelectAllImages = () => {
        const allPhotoIds = photos.map((photo) => {
            return photo.getId()!
        })

        if (_.isEqual(allPhotoIds, selectedImages)) {
            setSelectedImages([])
        }
        else {
            setSelectedImages(allPhotoIds)
        }
    }

    const renderImages = () => {
        return photos.map((photo: PreviewImage, index) => {
            if (!photo.getId()) return null
            const image = {
                height: 171,
                width: 232,
                src: photo.getImageUrl512x(),
                alt: 'landcape',
                placeholder: photo.getImageUrl128x(),
                key: photo.getId()
            }

            return (
                <div
                    key={photo.getId()}
                    className={`${n('image-container')} ${selectedImages.includes(photo.getId()!) ? 'selected' : ''}`}
                    onClick={() => {
                        if (selectMode) {
                            photo.getId() &&
                                handleSelectImage(photo.getId()!)
                        }
                        else {
                            setLightboxIndex(index)
                            setLightBoxOpen(true)
                        }
                    }}>
                    <LazyLoadImage
                        className={n('image')}
                        threshold={100}
                        key={image.key}
                        height={image.height}
                        src={image.src} // use normal <img> attributes as props
                        width={image.width}
                        effect="opacity"
                        alt={image.alt}
                        placeholderSrc={image.placeholder}
                    />
                    {/* <img className={n('image')} src={photo.getImageUrl512x()} alt="gallery item" /> */}
                    {/* <img className={n('image')} src={photo.getImageUrl512x()} alt="gallery item" /> */}
                </div>
            )
        })
    }

    const EditImagesMenu = <Menu>
        <Menu.Item onClick={handleSelectAllImages}>
            <div>{selectedImages.length === photos.length ? 'Unselect All' : 'Select All'}</div>
        </Menu.Item>
        <Menu.Item onClick={() => setUploadModalVisible(!uploadModalVisible)}>
            <div>Upload</div>
        </Menu.Item>
        {
            selectedImages.length > 0 ?
                <Menu.Item onClick={() => {
                    setDeleteModalVisible(!deleteModalVisible)
                }} >
                    <div>Delete</div>
                </Menu.Item>
                : null
        }


    </Menu>

    const uploadButton = () => {
        return <Button
            text="Upload"
            onClick={() => setUploadModalVisible(true)}
        />
    }

    const editImagesButton = () => {
        return <Dropdown overlay={EditImagesMenu} trigger={['click']}>
            <Button
                text="Edit Gallery"
                onClick={() => { console.log('') }}
            />
        </Dropdown>
    }

    const renderLightbox = () => {
        return (
            <Lightbox
                imagePadding={100}
                mainSrc={photos[lightBoxIndex].getImageUrlRaw()}
                nextSrc={photos[(lightBoxIndex + 1) % photos.length].getImageUrlRaw()}
                prevSrc={photos[(lightBoxIndex + photos.length - 1) % photos.length].getImageUrlRaw()}
                onCloseRequest={() => setLightBoxOpen(false)}
                onMovePrevRequest={() => {
                    const newLightboxIndex = (lightBoxIndex + photos.length - 1) % photos.length
                    setLightboxIndex(newLightboxIndex)
                }}
                onMoveNextRequest={() => {
                    const newLightboxIndex = (lightBoxIndex + 1) % photos.length
                    setLightboxIndex(newLightboxIndex)
                }}
            />
        )
    }

    const loadPhotos = () => {
        if (props.loading) {
            return <Spin size="large" />
        }
        else if (photos.length === 0) {
            return <div className={n('no-photos')}>
                This group has no photos.
                <br></br>
                <br></br>
                You can add photos by using the upload button.
                <br></br>
                <br></br>

                <div className={n('')}>
                    {uploadButton()}
                </div>
            </div>
        }
        else return <><div className={n('top')}>
            {/* <img className={n('photos-icon')} src={PHOTOS} alt="" />
            <div className={n('photos-text')}>
                All Photos
            </div> */}
            <div className={n('switch')}>
                <div className={n('switch-title')}>{selectMode ? "Mode: Select" : "Mode: View"}</div>
                <Switch defaultChecked={true} onChange={() => setSelectMode(!selectMode)} />
            </div>
            {editImagesButton()}
        </div>
            <Space height={"3rem"} />
        </>
    }


    return (
        <div className={pageName}>
            {photos.length > 0 && lightBoxOpen && renderLightbox()}
            {loadPhotos()}
            <>
                {/* DELETE MODAL */}
                <Modal
                    className={n('delete-modal')}
                    title="Are you sure you want to delete the selected images?"
                    okButton={{
                        text: 'Delete',
                        loading: deleteBtnLoading,
                        onClick: () => {
                            setDeleteBtnLoading(true)
                            const promises = selectedImages.map(imageId => {
                                return removeGroupGalleryImage(API_CONFIG, getSession()!, props.groupId, imageId)
                            })
                            Promise.all(promises)
                                .then(resp => {
                                    // setTimeout(() => {
                                    // toastr.success('Images successfully deleted')
                                    fetchGallery.emit('fetch')
                                    setDeleteModalVisible(false)
                                    setSelectedImages([])
                                    // }, 500);
                                })
                                .catch(err => {
                                    errorToString(err).forEach(err => toastr.error(err))
                                })
                                .finally(() => {
                                    // TODO: fetch is emitting before backend can process images
                                    setDeleteBtnLoading(false)

                                })
                        },
                    }}
                    cancelButton={{
                        onClick: () => {
                            setDeleteModalVisible(!deleteModalVisible)
                        }
                    }}
                    visible={deleteModalVisible}
                />


                {/* UPLOAD MODAL */}
                <Modal
                    className="uploadModal"
                    title="Upload Images"
                    visible={uploadModalVisible}
                    okButton={{
                        text: 'Upload',
                        loading: uploadBtnLoading,
                        disabled: ((!fileList) || (fileList.length < 1)) ? true : false,
                        onClick: () => {
                            if (fileList && fileList.length > 0) {
                                setUploadBtnLoading(true)
                                const promises = fileList.map(file => {
                                    return handleGalleryUpload(file)
                                })
                                Promise.all(promises)
                                    .then((resp) => {
                                        toastr.success('Images successfully uploaded')
                                        fetchGallery.emit('fetch')
                                        setUploadModalVisible(false)
                                        setUploadBtnLoading(false)
                                    })
                                    .catch(err => {
                                        errorToString(err).forEach(err => toastr.error(err))
                                        setUploadBtnLoading(false)
                                    })
                            }
                        }
                    }}
                    cancelButton={{
                        onClick: () => {
                            setUploadModalVisible(!uploadModalVisible)
                        }
                    }}
                >
                    {/* <ImgCrop aspect={540 / 300}> */}
                    <Dragger
                        name={'file'}
                        beforeUpload={(file: RcFile, rawFileList) => {
                            if (fileList && fileList.length > 0) {
                                setFileList([...rawFileList, ...fileList])
                            }
                            else {
                                setFileList(rawFileList)
                            }
                            return false
                        }}
                        onPreview={handlePreview}
                        accept='.png, .jpg'
                        maxCount={10}
                        multiple={true}
                        showUploadList={true}
                        listType={'picture-card'}
                        onRemove={(fileToBeRemoved) => {
                            if (fileList) {
                                const newFileList = fileList.filter((fileListFile) => {
                                    return fileToBeRemoved.uid !== fileListFile.uid
                                })
                                setFileList(newFileList)
                            }

                        }}

                        className="uploadBox">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag images here</p>
                        <p className="ant-upload-hint">
                            Limit: up to 10 images of type .png or .jpg
                        </p>
                    </Dragger>
                    {/* </ImgCrop> */}
                    <PreviewImageModal
                        visible={previewVisible}
                        title={previewTitle}
                        onCancel={() => setPreviewVisible(false)}
                        onOk={() => setPreviewVisible(false)}
                    >
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </PreviewImageModal>
                </Modal>

                <div className={n('container')}>
                    {renderImages()}
                </div>
            </>
        </div>

    )
}

export default Gallery