import React from 'react'
import { Spacing } from '../../enums/spacing'
import { setClassName } from '../../utils/setClassName'
import Space from './Space'

interface TitleProps {
    text: string;
    size?: 'small' | 'large'
    className?: string;
    marginBottom?: boolean;
}

const Title = (props: TitleProps) => {
    const componentName = "Title"
    const n = setClassName(componentName)

    return (
        <div className={
            n(props.size || 'medium') + ' ' + props.className
        }>
            {props.text}
            {
                props.marginBottom ?

                    <Space height={Spacing.small} /> :
                    null
            }
        </div>
    )
}

export default Title