import { User, updateUserMe, verifyPhone } from 'joinr-dashboard-client-api'
import React, { useContext, useEffect, useState } from 'react'
import * as toastr from 'toastr'
import { errorToString } from '../../api/errors'
import { getSession } from '../../api/SessionCompat'
import { API_CONFIG } from '../../App'
import { isBlank } from '../../utils/notBlank'
import { setClassName } from '../../utils/setClassName'
import Modal, { ModalProps } from '../generic/Modal'
import Space from '../generic/Space'
import { Spacing } from '../../enums/spacing'
import { UserContext } from '../../context/user'
import validator from 'validator'
import LocationDropdown from '../LocationDropdown'
import { getLatAndLong, useWaypointFormatter } from '../../hooks/useWaypointFormatter'
import { Waypoint } from 'joinr-dashboard-client-api/lib/api/models/Waypoint'
import { fetchUserMe } from '../../emitters/fetchUserMe'
import { Input } from '../generic'
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { formatPhone } from '../../utils/formatPhone'

const pageName = 'ModalEditProfile'
const n = setClassName(pageName)

interface ModalEditProfile extends ModalProps {
    user: User
}

const ModalEditProfile = (props: ModalEditProfile) => {

    // HOOKS
    const { formatToJoinrWaypoint } = useWaypointFormatter();

    // CONTEXT
    const { user } = useContext(UserContext)

    // STATES
    const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [description, setDescription] = useState<string | undefined>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [location, setLocation] = useState<Waypoint | undefined>(undefined)

    const [smsModalVis, setSmsModalVis] = useState<boolean>(false)
    const [smsLoading, setSmsLoading] = useState<boolean>(false)
    const [smsCode, setSmsCode] = useState<string>('')

    const [canResend, setCanResend] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(60);

    // Add this effect to handle the timer
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (smsModalVis && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }

        if (timer === 0) {
            setCanResend(true);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [smsModalVis, timer]);

    // Add a resend function
    const handleResend = () => {
        // Add your resend verification code logic here
        setTimer(60);
        setCanResend(false);
    };


    useEffect(() => {
        setInitialValues()
    }, [user])

    const validateInputs = () => {
        if (isBlank(name)) return 'Please enter a valid group name'
        if (!validator.isEmail(email)) return 'Please enter a valid email address'
        else return
    }

    const setInitialValues = () => {
        if (!user) return

        setName(user.displayName || '')
        setDescription(user.status || '')
        setEmail(user.emailAddress || '')
        setPhoneNumber(formatPhone(user.phoneNumber || ''))
        setLocation(user.homeLocation || undefined)

    }

    const updateUser = async () => {
        if (!validateInputs()) {
            setSaveBtnLoading(true)
            if (!user) return

            // changeGroupOwner(API_CONFIG, getSession()!,group.getId()!)
            updateUserMe(API_CONFIG, getSession()!, {
                email_address: email,
                display_name: name,
                phone_number: phoneNumber,
                status: description,
                home_location: location
            })
                .then((resp) => {
                    toastr.success('Your info was successfully updated')
                    props.changeVisible
                        && props.changeVisible(false)
                    if (formatPhone(phoneNumber) !== formatPhone(user.phoneNumber)) {
                        setSmsModalVis(true)
                    } else {
                        fetchUserMe.emit('fetch')
                    }
                })
                .catch(err => {
                    errorToString(err).forEach(err => toastr.error(err))
                })
                .finally(() => {
                    setSaveBtnLoading(false)
                })
        }
    }

    return (
        <div className={pageName}>

            {/* VERIFY PHONE MODAL */}
            <Modal
                title={`Update Phone Number`}
                okButton={{
                    text: "Confirm",
                    onClick: () => {
                        setSmsLoading(true)
                        verifyPhone(API_CONFIG, getSession()!, {
                            phone_number: phoneNumber,
                            code: smsCode
                        })
                            .then(resp => {
                                fetchUserMe.emit('fetch')
                                toastr.success('Your phone number was successfully updated!')
                                setSmsModalVis(false)
                            })
                            .catch(err => {
                                errorToString(err).forEach(err => toastr.error(err))
                            })
                            .finally(() => {
                                setSmsLoading(false)
                            })
                    },
                    loading: smsLoading
                }}
                visible={smsModalVis}
                changeVisible={setSmsModalVis}
                outsideClickClose={false}
                description={<>Enter the SMS code sent to <span style={{ color: '#ee002e' }}>{phoneNumber}</span></>}
            >
                <Input
                    name="SMS Code"
                    placeholder='Enter your SMS code'
                    onInputChange={(value: string) => { setSmsCode(value) }}
                />
            </Modal>

            {/* EDIT GROUP MODAL */}
            <Modal
                okButton={{
                    text: "Save",
                    loading: saveBtnLoading,
                    tooltipError: validateInputs(),
                    onClick: () => {
                        updateUser()
                    },
                }}
                cancelButton={{
                    loading: saveBtnLoading,
                    onClick: () => {
                        if (!user) return
                        setInitialValues()
                    },
                    style: "cancel"
                }}
                visible={props.visible} // TODO: Change back to props.visible
                changeVisible={props.changeVisible}
                outsideClickClose={false}
            >
                <Input
                    className={n('name')}
                    title={'Full Name'}
                    onInputChange={(value) => setName(value)}
                    inputValue={name}
                />
                <Space height={Spacing.input25} />
                <Input
                    textArea={true}
                    textAreaEditable={true}
                    title={'Description'}
                    onInputChange={(value) => setDescription(value)}
                    inputValue={description}
                />
                <Space height={Spacing.input25} />
                <LocationDropdown
                    title={"Location"}
                    titleColor={'#FFF'}
                    titleBold
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onInputChange={() => { }}
                    defaultValue={`${user.homeLocation?.city}, ${user.homeLocation?.state}, ${user.homeLocation?.country}`}
                    onWaypointSelected={(value: google.maps.places.PlaceResult) => {
                        if (value.geometry) {
                            console.log(value.address_components)
                            const position = getLatAndLong(value.geometry);
                            const formattedWaypoint = formatToJoinrWaypoint(value, {
                                type: "marker",
                                position,
                                sort_order: 0
                            })
                            setLocation(formattedWaypoint)
                        }
                    }}
                // defaultValue={formattedAddress(props.waypoint)}
                />
                <Space height={Spacing.input25} />
                <div className={n(' column-layout')}>
                    <Input
                        className={n('email')}
                        title={'Email'}
                        onInputChange={(value) => setEmail(value)}
                        inputValue={email}
                    />
                    <Input
                        className={n('phoneNumber')}
                        title={'Contact No.'}
                        onInputChange={(value) => {
                            const formatted = formatPhone(value);
                            setPhoneNumber(formatted);
                        }}
                        inputValue={phoneNumber}
                    />
                </div>
                <Space height={'7.7rem'} />
            </Modal>
        </div >
    )
}

export default ModalEditProfile