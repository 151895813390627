import { useState, useEffect } from 'react';
import { ApiConfig, getGroupGallery, PreviewImage, Session } from 'joinr-dashboard-client-api';
import { fetchGallery } from '../emitters/fetchGallery';
import { errorToString } from '../api/errors';
import * as toastr from 'toastr'

interface UseGalleryDataProps {
    apiConfig: ApiConfig;
    session: Session;
    groupId: string;
}

interface UseGalleryDataReturn {
    gallery: PreviewImage[];
    galleryLoading: boolean;
}

const useGalleryData = ({ apiConfig, session, groupId }: UseGalleryDataProps): UseGalleryDataReturn => {
    const [galleryLoading, setGalleryLoading] = useState<boolean>(false);
    const [gallery, setGallery] = useState<PreviewImage[]>([]);

    useEffect(() => {
        const fetchGalleryData = () => {
            setGalleryLoading(true);
            getGroupGallery(apiConfig, session, groupId)
                .then((resp) => {
                    const sortedByDate = resp.sort(
                        (a: any, b: any) => a.getCreatedAt()! - b.getCreatedAt()!
                    );
                    setGallery(sortedByDate);
                })
                .catch(err => {
                    errorToString(err).forEach(err => toastr.error(err));
                })
                .finally(() => {
                    setGalleryLoading(false);
                });
        };

        fetchGallery.on('fetch', fetchGalleryData);
        fetchGallery.emit('fetch');

        return () => {
            fetchGallery.removeAllListeners('fetch');
        };
    }, [groupId, apiConfig, session]);

    return { gallery, galleryLoading };
};

export default useGalleryData;