// hooks/useTableSearch.ts
import { useMemo } from 'react';
import { Member, User } from 'joinr-dashboard-client-api';
import { TableRow, SortConfig } from '../models/table';
import { USER_PLACEHOLDER } from '../assets';

export const renderSortItems = (handleSort: (value: string) => void) => {
    const fields = [
        { field: 'name', label: 'Name' },
        { field: 'email', label: 'Email' },
        { field: 'city', label: 'City' },
        { field: 'date', label: 'Date', asc: 'Oldest first', desc: 'Newest first' }
    ];

    return fields.flatMap(({ field, label, asc = 'A to Z', desc = 'Z to A' }) => [
        { text: `${label} (${asc})`, onClick: () => handleSort(`${field}:asc`) },
        { text: `${label} (${desc})`, onClick: () => handleSort(`${field}:desc`) }
    ]);
};

export const getPageNumbers = (totalPages: number, currentPage: number) => {
    const delta = 1;
    const pages: (number | string)[] = [];

    for (let i = 1; i <= totalPages; i++) {
        if (
            i === 1 ||                                    // First page
            i === totalPages ||                          // Last page
            (i >= currentPage - delta && i <= currentPage + delta)  // Pages around current
        ) {
            pages.push(i);
        } else if (
            (i === 2 && currentPage - delta > 2) ||           // Show ellipsis after first page
            (i === totalPages - 1 && currentPage + delta < totalPages - 1)  // Show ellipsis before last page
        ) {
            pages.push('...');
        }
    }

    return pages;
};

const mapToTableRows = (items: (Member[] | User[])): TableRow[] => {
    return items.map(item => {
        // Check if the item is a Member by looking for the 'user' property
        const isMember = 'user' in item;
        const user = isMember ? (item as Member).user : item as User;

        // Create the base table row
        const baseRow: TableRow = {
            id: user?.getId() || '',
            image: user?.previewImage?.getImageUrl64x() || USER_PLACEHOLDER,
            name: user?.displayName || 'Unknown',
            email: user?.emailAddress || 'Email Hidden',
            city: user?.homeLocation?.city || 'Location Hidden',
            date: user?.lastOnline?.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            }).replace(',', '') || 'Unknown Join Date'
        };

        // Add Member-specific properties if the item is a Member
        if (isMember) {
            const member = item as Member;
            return {
                ...baseRow,
                kicked: member.kicked,
                left: member.left
            };
        }

        return baseRow;
    });
};

export const searchTable = (
    data: (Member[] | User[]) | undefined,
    searchTerm: string,
    sortConfig: SortConfig
) => {
    // Keep just the core data transformation logic
    return useMemo(() => {
        if (!data) return [];
        console.log(data)
        const mappedData = mapToTableRows(data);

        // Filter
        const filteredData = searchTerm ? mappedData.filter(row =>
            row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            row.city.toLowerCase().includes(searchTerm.toLowerCase())
        ) : mappedData;

        // Sort
        return filteredData.sort((a, b) => {
            if (sortConfig.field === 'date') {
                const parseDate = (dateStr: string) => {
                    const [day, month, year] = dateStr.split(' ');
                    const monthMap: { [key: string]: number } = {
                        'Jan': 0, 'Feb': 1, 'Mar': 2, 'Apr': 3, 'May': 4, 'Jun': 5,
                        'Jul': 6, 'Aug': 7, 'Sep': 8, 'Oct': 9, 'Nov': 10, 'Dec': 11
                    };
                    return new Date(parseInt(year), monthMap[month], parseInt(day));
                };

                const dateA = parseDate(a.date);
                const dateB = parseDate(b.date);
                return sortConfig.ascending ?
                    dateA.getTime() - dateB.getTime() :
                    dateB.getTime() - dateA.getTime();
            }

            const aValue = a[sortConfig.field].toLowerCase();
            const bValue = b[sortConfig.field].toLowerCase();
            return sortConfig.ascending ?
                aValue.localeCompare(bValue) :
                bValue.localeCompare(aValue);
        });
    }, [data, searchTerm, sortConfig]);
};