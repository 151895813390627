import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { UserContext } from '../context/user';
import { GroupPage } from '../models/grouppage';
import UserTable from '../components/generic/Table';
import Modal from '../components/generic/Modal';
import { TableRow } from '../models/table';
import { addMember, kickMember } from 'joinr-dashboard-client-api';
import { errorToString } from '../api/errors';
import { fetchSpecificGroup } from '../emitters/fetchGroups';
import * as toastr from 'toastr';
interface MembersProps extends GroupPage {
}

const Members = (props: MembersProps) => {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(false)
    const [memberToKick, setMemberToKick] = useState<TableRow | undefined>()
    const [kickModalVis, setKickModalVis] = useState(false)

    const handleKick = (row: TableRow) => {
        setLoading(true)
        memberToKick &&
            kickMember(props.apiConfig, props.session, props.groupId, memberToKick.id)
                .then(resp => {
                    console.log(resp)
                    fetchSpecificGroup.emit('fetch')
                    setKickModalVis(false)
                    toastr.success(`${row.name} was kicked from the group`)
                }).catch(err => {
                    errorToString(err).forEach(err => toastr.error(err))
                }).finally(() => {
                    setLoading(false)
                    setMemberToKick(undefined)
                })
    }

    const kickModal = () => {
        return (
            <Modal
                title={`KICK GROUP MEMBER`}
                okButton={{
                    text: "Confirm",
                    onClick: () => { memberToKick && handleKick(memberToKick) },
                    loading: loading
                }}
                visible={kickModalVis}
                changeVisible={setKickModalVis}
                outsideClickClose={false}
                description={`You are about to kick ${memberToKick?.name} from ${props.group?.title}, are you sure?`}
            >
            </Modal>
        )
    }


    return (
        <>
            {kickModal()}
            <UserTable
                data={props.group?.members}
                loading={!props.group}
                pageName="Members"
                onAddClick={() => console.log('ok')}
                showLeader={true}
                leaderInfo={{
                    image: user?.previewImage?.getImageUrl64x() || '',
                    name: user?.displayName || ''
                }}
                actionItems={[
                    {
                        text: "Kick",
                        onClick: (row) => {
                            setMemberToKick(row)
                            setKickModalVis(true)
                        }
                    }
                ]}
            />
        </>
    );
};

export default Members;