import React, { useEffect } from 'react';
import { Select as AntSelect } from 'antd';
import { Select } from './generic';
import { User } from 'joinr-dashboard-client-api';
import { Member } from 'joinr-dashboard-client-api';
import { setClassName } from '../utils/setClassName';
import { fetchChangeGroupOwner } from '../emitters/fetchChangeGroupOwner';
import { Group } from 'joinr-dashboard-client-api';
import { getSession } from '../api/SessionCompat';
import { changeGroupOwner } from 'joinr-dashboard-client-api';
import { fetchSpecificGroup } from '../emitters/fetchGroups';
import { errorToString } from '../api/errors';
import { API_CONFIG } from '../App';
import * as toastr from 'toastr'

const { Option } = AntSelect;
const componentName = 'SelectLeader'
const n = setClassName(componentName)

interface SelectLeaderProps {
    members?: Member[];
    leader?: User;
    group: Group;
    setLeader: (user: User) => void;
    groupId: string;
}

const SelectLeader = React.memo((props: SelectLeaderProps) => {
    const transformId = (user: User) => {
        return {
            ...user,
            _id: user.getId()
        } as unknown as User
    }

    const handleChangeGroupOwner = () => {
        if (props.group?.owner === props.leader) return
        const newLeader = props.leader as User
        transformId(newLeader)
        changeGroupOwner(API_CONFIG, getSession()!, props.groupId, { user: transformId(newLeader) })
            .then(resp => {
                fetchSpecificGroup.emit('fetch')
                toastr.success(`The group leader was successfully changed to ${newLeader.displayName}`)
            }).catch(err => {
                errorToString(err).forEach(err => toastr.error(err))
            })
    }

    useEffect(() => {
        const onFetch = () => handleChangeGroupOwner();
        fetchChangeGroupOwner.on('fetch', onFetch);
        return () => {
            fetchChangeGroupOwner.off('fetch', onFetch);
        };
    }, [props.leader]);

    return (
        <>
            {
                <Select
                    title="Admin / Leader"
                    className={componentName}
                    dropdownRender={(menu) => <>{menu}</>}
                    optionLabelProp="label"
                    showSearch
                    placeholder={props.leader?.displayName || "Select a leader"}
                    defaultValue={props.leader?.displayName}
                    value={props.leader?.displayName}
                    optionFilterProp="children"
                    onSelect={(memberName: any, option: any) => {
                        const id = option.key;
                        const newLeader = props.group.members!.find((member) => member.id === id);
                        if (newLeader?.user) {
                            props.setLeader(newLeader.user);
                        }
                    }}
                    filterOption={(input, option) =>
                        (option!.value as string).toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {props.group.members?.map((member) => {
                        if (!member.user) return null;
                        const { displayName } = member.user;
                        return (
                            <Option
                                className="option-name"
                                key={member.id}
                                value={displayName}
                                label={displayName}
                            >
                                {displayName}
                            </Option>
                        );
                    })}
                </Select>
            }
        </>
    );
});

export default SelectLeader;