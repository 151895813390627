import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/user';
import UserTable from '../components/generic/Table';
import { User, getContacts, removeFavoriteUser } from 'joinr-dashboard-client-api';
import { errorToString } from '../api/errors';
import Space from '../components/generic/Space';
import { TableRow } from '../models/table';
import Modal from '../components/generic/Modal';
import { PageGeneric } from '../models/pagegeneric';
import * as toastr from 'toastr';
import useFetchContacts from '../hooks/useFetchContacts';
import { fetchContacts } from '../emitters/fetchContacts';

interface ContactsProps extends PageGeneric {
    contacts: User[]
    loading: boolean;
}

const Contacts = (props: ContactsProps) => {
    const { user } = useContext(UserContext);
    const [contactToRemove, setContactToRemove] = useState<TableRow | undefined>()
    const [removeModalVis, setRemoveModalVis] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)

    const handleRemoveUser = (row: TableRow) => {
        setRemoveLoading(true)
        contactToRemove &&
            removeFavoriteUser(props.apiConfig, props.session, contactToRemove.id)
                .then(resp => {
                    fetchContacts.emit('fetch')
                    setRemoveModalVis(false)
                    toastr.success(`${row.name} was removed from your contacts.`)
                }).catch(err => {
                    errorToString(err).forEach(err => toastr.error(err))
                }).finally(() => {
                    setRemoveLoading(false)
                    setContactToRemove(undefined)
                })
    }

    const removeUserModal = () => {
        return (
            <Modal
                title={`REMOVE CONTACT`}
                okButton={{
                    text: "Confirm",
                    onClick: () => { contactToRemove && handleRemoveUser(contactToRemove) },
                    loading: removeLoading
                }}
                visible={removeModalVis}
                changeVisible={setRemoveModalVis}
                outsideClickClose={false}
                description={`You are about to remove ${contactToRemove?.name} from your contacts list, are you sure?`}
            >
            </Modal>
        )
    }

    return (
        <>
            {removeUserModal()}
            <div className="Contacts-wrapper">
                <Space height="10rem" />
                <UserTable
                    data={props.contacts}
                    loading={props.loading}
                    pageName="Contacts"
                    onAddClick={() => { console.log('ok') }}
                    showLeader={true}
                    leaderInfo={{
                        image: user?.previewImage?.getImageUrl64x() || '',
                        name: user?.displayName || ''
                    }}
                    actionItems={[
                        {
                            text: "Remove",
                            onClick: (row) => {
                                setContactToRemove(row)
                                setRemoveModalVis(true)
                            }
                        }
                    ]}
                />
            </div>
        </>
    );
};

export default Contacts;