import React from "react";
import { setClassName } from "../../utils/setClassName";
import { DownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown as AntDropdown, Typography } from 'antd';
import { ELLIPSIS } from "../../assets";
import Space from "./Space";

interface DropdownItem {
    text: string,
    onClick?: (item?: any) => void;
}

interface DropdownProps {
    items: DropdownItem[]
    icon?: string;
    paginationPage?: number;
}

const Dropdown = (props: DropdownProps) => {

    const componentName = "Dropdown"
    const n = setClassName(componentName)


    const populateItems = (): MenuProps['items'] => {
        return props.items.map((item, index) => ({
            key: item.text,
            label: (
                <div className={n(`option ${index}`)} onClick={(e) => {
                    e.stopPropagation();
                    item.onClick?.();
                }}>
                    {item.text}
                </div>
            )
        }));
    };

    const items = populateItems()


    const handleIcon = () => {
        if (props.paginationPage) {
            return <>
                <div className={n('pagination')}>
                    <div className={n('pagination-page-border')}>
                        <div className={n('pagination-page')}>
                            {props.paginationPage}
                        </div>
                    </div>
                </div>

            </>
        }
        else if (props.icon) {
            return <img className={n('icon')} src={props.icon} alt="" />
        }
        else return <img className={n('icon')} src={ELLIPSIS}></img>
    }

    const renderButton = () => {
        return <AntDropdown.Button
            className="testing"
            trigger={['click']}
            icon={handleIcon()}
            menu={{
                items,
            }}
        >
        </AntDropdown.Button>
    }

    const renderComponent = () => {
        if (props.paginationPage) {
            return <>
                <div className={n('showing-text')}>
                    Showing
                </div>
                {renderButton()}
                <div className={n('pagination-description')}>
                    items per page
                </div>
            </>
        }
        else {
            return renderButton()
        }
    }

    return (
        <div className={componentName}>
            {renderComponent()}
        </div >
    );
}

export default Dropdown