import { Input, Select as AntSelect } from "antd";
import React from "react";
import { setClassName } from "../../utils/setClassName";

type AntdSelectProps = React.ComponentProps<typeof AntSelect>;

interface SelectProps extends AntdSelectProps { // Exclude onSelect to override it
    title?: string;
}

const Select = (props: SelectProps) => {
    const componentName = "Select";
    const n = setClassName(componentName);

    return (
        <div className={componentName}>
            {props.title && <div className={"joinr-form-subtitles"}>{props.title}</div>}
            <Input.Group>
                <AntSelect
                    {...props} // Pass all props to the Select component
                    size="small"
                    style={{ width: "100%" }}
                >
                    {props.children}
                </AntSelect>
            </Input.Group>
        </div>
    );
};

export default Select;
