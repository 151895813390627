import { parsePhoneNumber } from "libphonenumber-js";

export const formatPhone = (phone: string | undefined, formatForInput = false) => {
    if (!phone) return '';

    try {
        // Clean input and ensure proper '+' prefix
        const cleaned = phone.replace(/[^\d+]/g, '');
        const formattedInput = cleaned.startsWith('+') ? cleaned : `+${cleaned}`;

        const phoneNumber = parsePhoneNumber(formattedInput);
        if (!phoneNumber) return phone;

        if (formatForInput) {
            // Format for input field (with parentheses for US)
            if (phoneNumber.country === 'US') {
                const national = phoneNumber.format('NATIONAL');
                return `+1 ${national}`;
            }
            return phoneNumber.format('INTERNATIONAL');
        } else {
            // Format for display (with dashes)
            if (phoneNumber.country === 'US') {
                const formatted = phoneNumber.format('NATIONAL');
                return `+1-${formatted.replace(/[()]/g, '').replace(/\s/g, '-')}`;
            }
            return phoneNumber.format('INTERNATIONAL').replace(/\s/g, '-');
        }
    } catch (e) {
        return phone;
    }
};