const BELL = require('./bell.svg');
const CREDITCARD = require('./creditcard.svg');
const EDIT = require('./edit.svg');
const SECURITY = require('./security.svg');
const MAIL = require('./mail.svg');
const PHONE = require('./phone.svg');
const GLOBE = require('./globe.svg');
const CAMERA = require('./camera.svg');

export {
    BELL,
    CREDITCARD,
    EDIT,
    SECURITY,
    MAIL,
    PHONE,
    GLOBE,
    CAMERA
};