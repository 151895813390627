import React from "react";
import { setClassName } from "../../utils/setClassName";
import { Switch, SwitchProps } from "antd";
import Space from "./Space";

interface SettingProps {
    icon: string;
    title: string;
    description?: string;
    toggleswitch?: SwitchProps;
    titleWhite?: boolean;
    onClick?: () => void;
}

const componentName = 'Setting'
const n = setClassName(componentName)

const Setting = ({ icon, title, description, toggleswitch, titleWhite, onClick }: SettingProps) => {
    const renderSwitch = () => {
        if (toggleswitch) {
            return <>
                <Space maxWidth='100%' />
                <Switch
                    {...toggleswitch}
                    className={n('switch')}
                />
            </>
        }
    }
    return (
        <div className={componentName}>
            <div className={n('switch-wrapper')} onClick={onClick}>
                <img className={n('icon')} src={icon} alt="" />
                <div className={n('switch-wrapper-inner')}>
                    <div className={`${n('title')} ${titleWhite ? n('title-white') : ''}`}>{title}</div>
                    <div className={n('form-display-text')}>{description}</div>
                </div>
                {renderSwitch()}
            </div>
        </div>
    )
}

export default Setting