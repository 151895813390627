import { GroupDay } from 'joinr-dashboard-client-api'
import { Route } from 'joinr-dashboard-client-api'
import { Waypoint as WaypointInterface } from 'joinr-dashboard-client-api/lib/api/models/Waypoint'
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from "react-datepicker"
import * as toastr from 'toastr'
import { v4 as uuidv4 } from 'uuid'
import { CHEVRON, DELETE } from '../assets'
import { GMAP_ICON } from '../assets/img/roadbook'
import { setClassName } from '../utils/setClassName'
import Input from './generic/Input'
import Modal from './generic/Modal'
import Waypoint from './Waypoint'
import { RoadbookContext } from '../context/roadbook'
import { generateDay } from '../utils/roadbookutils'
interface RoadbookDayProps {
    groupDay: GroupDay;
    index: number
    activeGroupDay: GroupDay | undefined;
    onActiveGroupDayChange?: (groupDay: GroupDay | null) => void;
    addWaypointClicked?: boolean;
    editMode: boolean;
}

const RoadbookDay = forwardRef<HTMLDivElement, RoadbookDayProps>((props, ref) => {

    // REFS
    const roadbookDay = useRef<HTMLDivElement>(null)
    const roadbookDayTop = useRef<HTMLDivElement>(null)

    // GENERIC VARIABLES
    const componentName = "RoadbookDay"
    const n = setClassName(componentName)

    // STATE
    const [dayHeight, setDayHeight] = useState<number>(0)
    const [textAreaDidResize, setTextAreaDidResize] = useState<boolean>(false)
    const [deleteDayModalOpen, setDeleteDayModalOpen] = useState<boolean>(false)
    const [routeError, setRouteError] = useState<boolean>(false)

    // CONTEXT
    const { handleUpdateDirtyDays: updateDirtyDays, deleteDay, sortWaypoints, mergeNewWaypoint } = useContext(RoadbookContext)
    const todayIsActive = props.activeGroupDay && props.activeGroupDay.getId() === props.groupDay.getId()

    // DEFINE HEIGHT
    useEffect(() => {
        roadbookDay.current && setDayHeight(roadbookDay.current?.clientHeight)
    }, [roadbookDay.current?.clientHeight, textAreaDidResize])

    const addRoute = useCallback((routeUrl: string) => {
        const route = new Route({})
        route.routeUrl = routeUrl

        const newDay = generateDay(props.groupDay)
        newDay.route = route

        updateDirtyDays(newDay)
    }, [generateDay, props.groupDay, updateDirtyDays])

    const newWaypointSortOrder = () => {
        if (props.groupDay.waypoints.length < 2) {
            return props.groupDay.waypoints.length + 1
        }
        else return props.groupDay.waypoints.length - 1
    }

    // WAYPOINT FUNCTIONS
    const addWaypoint = useCallback(() => {
        if (!props.activeGroupDay) return
        if (props.activeGroupDay.getId() !== props.groupDay.getId()) return
        if (!props.groupDay) return
        const newDay = new GroupDay({})
        Object.assign(newDay, props.groupDay)
        const currentWaypoints = newDay.waypoints
        const id = uuidv4()

        const newWaypoint = {
            _id: id,
            position: {
                _id: id,
                latitude: 1,
                longitude: 1
            },
            sort_order: newWaypointSortOrder(),
            type: 'marker'
        }
        const newWaypoints = [...currentWaypoints, newWaypoint]

        const sorted = sortWaypoints(newWaypoints!)
        const freshDay = generateDay(props.groupDay)
        freshDay.waypoints = sorted
        mergeNewWaypoint(freshDay)
    }, [generateDay, mergeNewWaypoint, props.activeGroupDay?.getId(), props.groupDay, sortWaypoints])

    const lastAddWaypointClikedRef = useRef(props.addWaypointClicked);

    useEffect(() => {
        if (lastAddWaypointClikedRef.current != props.addWaypointClicked) {
            lastAddWaypointClikedRef.current = props.addWaypointClicked;
            addWaypoint()
        }
    }, [addWaypoint, lastAddWaypointClikedRef, props.addWaypointClicked])

    const deleteWaypoint = useCallback((waypoint: WaypointInterface) => {
        const filterOut = props.groupDay.waypoints.filter((day) => {
            return day._id !== waypoint._id
        })
        const newDay = props.groupDay
        newDay.waypoints = filterOut
        updateDirtyDays(newDay)
        toastr.success(deleteWaypointToast(waypoint.type), undefined, {
            positionClass: "toast-bottom-center",
            newestOnTop: true,
            toastClass: 'roadbook-toast'
        })
    }, [props.groupDay, updateDirtyDays])

    const deleteWaypointToast = (type: string) => {
        if (type === 'start') {
            return 'Route Start removed successfully'
        }
        if (type === 'end') {
            return 'Route End removed successfully'
        }
        else {
            return `Waypoint removed successfully`
        }
    }

    const calcGroupDayHeight = useMemo(() => {
        if (todayIsActive) return {
            height: dayHeight,
            overflow: 'hidden',
            transition: 'all 150ms',
        }
        else return {
            height: '4rem',
            overflow: 'hidden',
            transition: 'all 150ms'
        }

    }, [props.activeGroupDay, dayHeight])

    const toggleTextAreaResize = useCallback(() => {
        setTextAreaDidResize((val) => !val)
    }, [])

    return (
        <div
            ref={ref}
            className={`${componentName}`}
            style={calcGroupDayHeight}>
            <div ref={roadbookDay}>
                <div ref={roadbookDayTop} className={n('top')}>
                    <div className={n('left-container')}>
                        <div className={n('day-container')}>
                            <img
                                className={n(`chevron-icon ${todayIsActive ? '' : 'inactive'}`)}
                                onClick={() => {
                                    if (!props.onActiveGroupDayChange) return

                                    // const activeGroupDayID = props.groupDay.getId()!
                                    props.onActiveGroupDayChange(props.groupDay)
                                }}
                                src={CHEVRON}
                                alt=""
                            />
                            <div className={n('day-text')}>
                                {`DAY ${props.index + 1}`}
                            </div>
                        </div>

                        <div className={n('date-container')}>
                            <div className={n('date-text')}>
                                <DatePicker
                                    className={n('datePicker-date')}
                                    selected={roundToNearestHour(new Date(props.groupDay.activate_at))}
                                    onChange={(date) => {
                                        if (!date) return
                                        const newDay = generateDay(props.groupDay)
                                        newDay.activate_at = date.toString()
                                        updateDirtyDays(newDay)
                                    }}
                                    showTimeSelect
                                    timeFormat="h a"
                                    timeIntervals={60}
                                    timeCaption="Start"
                                    dateFormat="MM/dd/yy ha"
                                    disabled={!props.editMode}
                                />
                            </div>
                        </div>

                    </div>
                    <Input
                        className={n('gmap-input')}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onInputChange={(change) => {
                            setRouteError(!isRouteValid(change))
                            if (isRouteValid(change))
                                addRoute(change)
                        }}
                        placeholder={'Add route via google maps share link'}
                        defaultValue={props.groupDay.route?.getUrl}
                        width={'390px'}
                        error={routeError ? 'invalid route' : ''}
                    />
                    <img
                        className={n('gmaps-icon')}
                        src={GMAP_ICON}
                        alt="google maps"
                        onClick={() => {
                            window.open("https://www.google.com/maps", "_blank");
                        }}
                    />
                    <img
                        className={n('delete-icon')}
                        src={DELETE}
                        alt="delete"
                        onClick={() => {
                            setDeleteDayModalOpen(true)
                        }} />
                    <Modal
                        title={'Delete Day'}
                        visible={deleteDayModalOpen}
                        okButton={{
                            text: "Delete",
                            onClick: () => {
                                deleteDay(props.groupDay)
                                if (todayIsActive) {
                                    props.onActiveGroupDayChange &&
                                        props.onActiveGroupDayChange(null)
                                }
                                setDeleteDayModalOpen(false)
                            }
                        }}
                        changeVisible={setDeleteDayModalOpen}
                    >
                        <div className={n('')}>
                            This action will delete your waypoints and descriptions for this day. Are you sure?
                        </div>
                    </Modal>
                </div>


                {props.groupDay?.waypoints?.map((waypoint, index) => {
                    return (
                        <Waypoint
                            key={`${waypoint._id}_${props.groupDay.getId()}`}
                            groupDay={props.groupDay}
                            waypoint={waypoint}
                            index={index}
                            indexTotal={props.groupDay.waypoints.length}
                            onDeleteWaypointClicked={deleteWaypoint}
                            editMode={props.editMode}
                            onTextAreaResize={toggleTextAreaResize}
                        />
                    )
                })
                }
            </div>
        </div >
    )
});

const roundToNearestHour = (date: Date) => {
    date.setMinutes(date.getMinutes() + 30);
    date.setMinutes(0, 0, 0);
    return date;
}

export const isRouteValid = (routeText: string) => {
    const text = routeText.trim().replace(/\s/g, '');

    // Check for empty string
    if (text.length === 0) return true;

    // Check if it starts with maps.app.goo.gl without protocol
    if (text.includes('https://maps.app.goo.gl/') || text.includes('https://goo.gl')) return true

    return false;
}

export default RoadbookDay;
