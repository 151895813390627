import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { setClassName } from '../utils/setClassName'
import { CHEVRON, LOGO_LIGHT } from '../assets'
import { useLocation } from 'react-router';

interface NavItemGeneric {
    icon?: {
        main: string,
        alt?: string,
    },
    text: string,
    page?: string;
    onClick?: () => void;
    subItems?: NavItemGeneric[]
}
interface SidebarProps {
    items: NavItemGeneric[];
}

const Sidebar = (props: SidebarProps) => {
    const componentName = "Sidebar"
    const n = setClassName(componentName)
    const navigate = useNavigate()

    // For switchIcons() to work we need a default activeItem
    // Grab home item as soon as it gets passed, and set it to the default activeItem
    const grabHomeItem = props.items.find(item => item.page === "/") || props.items[0];
    const [activeItem, setActiveItem] = useState<NavItemGeneric>(grabHomeItem)
    const location = useLocation();


    const handlePathname = () => {
        const pathname = location.pathname.split('/')[1];
        if (pathname === '' || !pathname) {
            if (activeItem !== grabHomeItem) {
                setActiveItem(grabHomeItem)
            }
            return 'overview'
        }
        else return pathname
    }

    // Code for dynamic dropdown calc
    const dropdownItem = {
        marginTop: 1.4,
        height: 2
    }
    const dropdownItemStyles = {
        marginTop: `${dropdownItem.marginTop}rem`,
        height: `${dropdownItem.height}rem`
    }

    const calcDropdownHeight = useMemo(() => {
        if (activeItem && activeItem.subItems) {
            return {
                height: `${activeItem.subItems.length * (dropdownItem.height + dropdownItem.marginTop)}rem`,
                overflow: 'visible',
                transition: 'all 150ms',
            }
        } else {
            return {
                height: '0rem',
                overflow: 'hidden',
                transition: 'all 150ms'
            }
        }
    }, [activeItem])

    const switchIcons = (item: NavItemGeneric) => {
        if (!activeItem || !item.icon) return;
        return activeItem.text === item.text ? item.icon.main : item.icon.alt || item.icon.main;
    };

    const handleSelectedItem = (item: NavItemGeneric) => {
        // edge case because we have /groups/ and /group/
        if ((item.text === 'groups') && (handlePathname() === 'group')) {
            return n('selected')
        }
        else if (item.text === handlePathname()) {
            return n('selected')
        }
        else return ''
    }

    return (
        <div className={componentName}>
            <div className={n('logo-container')} onClick={() => { navigate('/') }}>
                <img
                    className={n('logo')}
                    src={LOGO_LIGHT}
                    alt="" />
                <div className={n('access-text')}>
                    access
                </div>
            </div>
            <div className={n('options-container')}>
                {props.items.map(item => {
                    return (
                        <div className={n(`option ${handleSelectedItem(item)}`)}
                            key={item.text}
                            onClick={() => {
                                setActiveItem(item)
                                item.onClick && item.onClick()
                                if (!item.page) {
                                    return
                                }
                                else if (item.page === '/') navigate('/')
                                else navigate('/' + item.page + '/')

                            }}
                        >
                            {/* MAIN ITEM */}
                            <div className={n('option-content')}>
                                <div className={n('option-icon-wrapper')}>
                                    <img className={n('option-icon')} src={switchIcons(item)} alt="option" />
                                </div>
                                <div className={n('option-text')}>
                                    {item.text.toUpperCase()}
                                </div>
                                {
                                    item.subItems && <div className={n(`option-dropdown-chevron ${item.text === activeItem?.text ? 'active' : ''}`)}>
                                        <img src={CHEVRON} alt="" />
                                    </div>
                                }
                            </div>

                            {/* SUBITEMS */}
                            {
                                item.subItems && <div className={n('dropdown-container')} style={calcDropdownHeight}>
                                    <div className={n('start-end-icon-container')}>
                                    </div>

                                    <div className={n('dropdown-items')}>
                                        {item.subItems?.map(item => {
                                            return <>
                                                <div
                                                    className={n('dropdown-item')}
                                                    style={{ ...dropdownItemStyles }}
                                                    onClick={() => {
                                                        console.log('k')
                                                    }}>
                                                    <div className={n('item-text')} key={item.text}>
                                                        {item.text}
                                                    </div>
                                                </div>

                                            </>
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>

        </div >
    )
}

export default Sidebar