const BELL = require('./bell.svg')
const FILTER = require('./filter.svg')
const SEARCH = require('./search.svg')
const CHAT = require('./chat.svg')

export {
    BELL,
    FILTER,
    SEARCH,
    CHAT
}