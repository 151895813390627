import * as toastr from 'toastr'

export const copyToClipboard = async (text: string | undefined) => {
    if (!text) return
    try {
        await navigator.clipboard.writeText(text);
        toastr.success('Item copied to clipboard!')
    } catch (err) {
        toastr.error('Could not copy item to clipboard.');
    }
};